import React, { useEffect, useState } from "react";
import "./homePage.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "react-slideshow-image/dist/styles.css";
import "../aboutUs/aboutUs.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FirstPage from "./firstPage";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import GA from "../functions/GA";
import selectCorrectUrls from "../../selectCorrectUrls";
import { isMobile, isIE } from "react-device-detect";
import VideosCarousel from "../claims/videosCarousel/videosCarousel";

const HomePage = () => {
  const dispatch = useDispatch();
  // const isLoginMenuOpen = useSelector((state) => state.isLoginMenuOpenReducer);

  const campaign = useSelector((state) => state.campaignReducer);

  const [picturesToDisplayInTheSlide, setPicturesToDisplayInTheSlide] =
    useState([]);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [isContinuePreviousQuote, setIsContinuePreviousQuote] = useState(false);

  //Get the date for the pictures with a date range
  const today = new Date();
  const dd = String(today.getDate());
  const mm = String(today.getMonth() + 1); //January is 0!
  const yyyy = today.getFullYear();

  const hours = today.getHours();
  const minutes = today.getMinutes();

  const [isGetAQuoteOpen, setIsGetAQuoteOpen] = useState(false);
  // const [LoginDisplay, setLoginDisplay] = useState("AGENT");

  useEffect(() => {
    setIsComponentLoaded(true);

    // const file = require("../../pictures.json");

    // setPicturesToDisplayInTheSlide(selectWhatPicturesToDisplay(file.pictures));

    Axios.get("https://www.uaig.net/uaichome/pictures.json")
      .then((response) => {
        setPicturesToDisplayInTheSlide(
          selectWhatPicturesToDisplay(response.data.pictures)
        );
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  const getTodaysDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd;

    return today;
  };

  const getCurrentTime = () => {
    var d = new Date(); // for now
    d.getHours(); // => 9
    d.getMinutes(); // =>  30
    d.getSeconds();

    let time = new Date().toTimeString().slice(0, 8);

    return time;
  };

  console.log(getTodaysDate());
  console.log(getCurrentTime());

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  function getUrlParams(search) {
    const hashes = search.slice(search.indexOf("?") + 1).split("&");
    const params = {};
    hashes.map((hash) => {
      const [key, val] = hash.split("=");
      params[key] = decodeURIComponent(val);
    });
    return params;
  }

  useEffect(() => {
    if (getUrlParams(window.location.hash).startquote === "undefined") {
      setIsGetAQuoteOpen(true);
    } else if (
      getUrlParams(window.location.hash).continuepreviousquote === "undefined"
    ) {
      setIsContinuePreviousQuote(true);
    }
  }, []);

  const selectWhatPicturesToDisplay = (props) => {
    const imagesToDisplay = [];

    const checkThatDateIsInRange = (picture) => {
      const pictureStartDate = picture.dateRange.start_date;
      const pictureEndDate = picture.dateRange.end_date;

      const pictureStartDateArray = pictureStartDate.split("/");
      const pictureEndDateArray = pictureEndDate.split("/");

      const pictureStartTimeArray = picture.timeRange.start_time;
      const pictureEndTimeArray = picture.timeRange.end_time;

      if (picture.timeRange === null) {
        if (
          pictureStartDateArray[0] <= mm &&
          pictureStartDateArray[1] <= dd &&
          pictureStartDateArray[2] <= yyyy
        ) {
          if (
            pictureEndDateArray[0] >= mm &&
            pictureEndDateArray[1] >= dd &&
            pictureEndDateArray[2] >= yyyy
          ) {
            return true;
          } else return false;
        } else return false;
      } else {
        if (
          pictureStartDateArray[0] <= mm &&
          pictureStartDateArray[1] <= dd &&
          pictureStartDateArray[2] <= yyyy &&
          pictureStartTimeArray[0] <= hours &&
          pictureStartTimeArray[1] <= minutes
        ) {
          if (
            pictureEndDateArray[0] >= mm &&
            pictureEndDateArray[1] >= dd &&
            pictureEndDateArray[2] >= yyyy &&
            pictureEndTimeArray[0] >= hours &&
            pictureEndTimeArray[1] >= minutes
          ) {
            return true;
          } else return false;
        } else return false;
      }
    };

    props.map((picture, index) => {
      if (picture.shown) {
        if (picture.dateRange === null) {
          imagesToDisplay.push(picture);
        } else if (checkThatDateIsInRange(picture) === true) {
          imagesToDisplay.push(picture);
        }
      }
    });

    return imagesToDisplay;
  };

  const Slideshow = () => {
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }

    const randomNumber = getRandomInt(9999);
    const todaysDate = mm + "_" + dd + "_" + yyyy + "_" + randomNumber;

    return (
      <div className="slide-container">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          stopOnHover={true}
          showThumbs={false}
          emulateTouch={true}
          transitionTime={500}
          interval={8000}
        >
          {picturesToDisplayInTheSlide.map((picture, index) => {
            if (window.document.documentMode) {
              return (
                <div style={{ marginTop: "50px" }} key={index}>
                 
                 {picture.href.substring(0,1) === "/" || picture.href.substring(0,1) === "#" ?   <Link
                    onClick={() => {
                      GA(picture.GAaction, picture.GAlabel);
                    }}
                    to={picture.href}
                    target={picture.target}
                    aria-label="Click to view more regarding this banner"
                    className={picture.href.substring(0,1)}
                  >
                    <img
                      style={{
                        borderRadius: "10px",
                        height: "350px",
                        width: "auto",
                      }}
                      href={picture.href}
                      alt={picture.alt}
                      src={`${picture.src}?${todaysDate}`}
                      effect="blur"
                    ></img>
                  </Link>  :  <a
                    onClick={() => {
                      GA(picture.GAaction, picture.GAlabel);
                    }}
                    href={picture.href}
                    target={picture.target}
                    aria-label="Click to view more regarding this banner"
                  >
                    <img
                      style={{
                        borderRadius: "10px",
                        height: "350px",
                        width: "auto",

                      }}
                       className={picture.href.substring(0,1)}
                      href={picture.href}
                      alt={picture.alt}
                      src={`${picture.src}?${todaysDate}`}
                      effect="blur"
                    ></img>
                  </a> }
                
                </div>
              );
            } else
              return (
                <div style={{ marginTop: "50px" }} key={index}>
                 {picture.href.substring(0,1) === "/" || picture.href.substring(0,1) === "#" ? <Link
                    onClick={() => {
                      GA(picture.GAaction, picture.GAlabel);
                    }}
                    to={picture.href}
                    target={picture.target}
                    aria-label="Click to view more regarding this banner"
                  >
                    <LazyLoadImage
                      style={{
                        borderRadius: "10px",
                      }}
                      href={picture.href}
                      alt={picture.alt}
                      src={`${picture.src}?${todaysDate}`}
                      effect="blur"
                    ></LazyLoadImage>
                  </Link> : <a
                    onClick={() => {
                      GA(picture.GAaction, picture.GAlabel);
                    }}
                    href={picture.href}
                    target={picture.target}
                    aria-label="Click to view more regarding this banner"
                  >
                    <LazyLoadImage
                      style={{
                        borderRadius: "10px",
                      }}
                      href={picture.href}
                      alt={picture.alt}
                      src={`${picture.src}?${todaysDate}`}
                      effect="blur"
                    ></LazyLoadImage>
                  </a>}
                </div>
              );
          })}
        </Carousel>
      </div>
    );
  };

  const continuePreviousQuoteComponent = () => {
    return (
      <div>
        <div
          onClick={() => {
            setIsContinuePreviousQuote(false);
            setIsGetAQuoteOpen(false);
          }}
          className={
            isContinuePreviousQuote || isGetAQuoteOpen
              ? "continue-previous-quote-dark-background-on"
              : "continue-previous-quote-dark-background-off"
          }
        ></div>
        <div
          className={
            isContinuePreviousQuote
              ? "continue-previous-quote-component-shown"
              : "continue-previous-quote-component-hidden"
          }
        >
          <div
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              paddingTop: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingLeft: "1rem",
              }}
            >
              <h3>Continue your previous quote </h3>
              <h2
                className="close-login-menu-icon"
                onClick={() => {
                  setIsContinuePreviousQuote(false);
                }}
                style={{ right: "0px", top: "8px" }}
                aria-label="Close"
              >
                <i
                  className="fa fa-times-circle"
                  style={{
                    fontSize: "1.5em",
                    color: "#01224f",
                  }}
                ></i>{" "}
                &nbsp;
              </h2>
            </div>
            <hr></hr>
          </div>

          <div>
            <FirstPage isOpened={isContinuePreviousQuote} />
          </div>
        </div>
      </div>
    );
  };

  const checkZipCodeValidation = () => {
    const firstTwo = zipCode.substring(0, 2);

    if (
      firstTwo === "73" ||
      firstTwo === "75" ||
      firstTwo === "76" ||
      firstTwo === "77" ||
      firstTwo === "78" ||
      firstTwo === "88" ||
      firstTwo === "79" ||
      firstTwo === "32" ||
      firstTwo === "33" ||
      firstTwo === "34"
    ) {
      Axios.post(
        `${
          selectCorrectUrls().insuredPortal
        }/insurednew/insured/sqzl/index?zipcode=${zipCode}&status=Y&time=${getCurrentTime()}&date=${getTodaysDate()}`
      )
        .then((response) => {})
        .catch((e) => {
          // console.log(e);
        });
    } else {
      Axios.post(
        `${
          selectCorrectUrls().insuredPortal
        }/insurednew/insured/sqzl/index?zipcode=${zipCode}&status=N&time=${getCurrentTime()}&date=${getTodaysDate()}`
      )
        .then((response) => {})
        .catch((e) => {
          // console.log(e);
        });
    }
  };
  const selectCorrectHrefForTheZip = (e) => {
    const firstTwo = zipCode.substring(0, 2);

    if (
      firstTwo === "73" ||
      firstTwo === "75" ||
      firstTwo === "76" ||
      firstTwo === "77" ||
      firstTwo === "78" ||
      firstTwo === "88" ||
      firstTwo === "79"
    ) {
      return `${
        selectCorrectUrls().quotePortal
      }/uaicins/txsale/quick/start/UAIS?campaign=${campaign}`;
    } else if (firstTwo === "32" || firstTwo === "33" || firstTwo === "34") {
      return `${
        selectCorrectUrls().quotePortal
      }/uaicins/sale/quick/index/UAI?campaign=${campaign}`;
    } else {
      return "javascript:void(0)";
    }
  };

  const getAQuoteComponent = () => {
    return (
      <div
        className={
          isGetAQuoteOpen
            ? "get-a-quote-component-shown"
            : "get-a-quote-component-hidden"
        }
      >
        <h2
          style={{ top: 15 }}
          className="close-login-menu-icon"
          onClick={() => {
            setIsGetAQuoteOpen(false);
          }}
        >
          <i
            className="fa fa-times-circle"
            style={{ fontSize: "1.5em", color: "#01224f" }}
            aria-hidden="true"
          ></i>
        </h2>
        <form target="_blank" autoComplete="false">
          <input
            // autoComplete="false"
            id="zip-input-box-2"
            maxLength="5"
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
            className="zip-input"
            placeholder="Enter zip code"
          ></input>

          <div
            onClick={() => {
              GA(
                "Start_Quote",
                "User clicked on Start Quote button, inside get a quote pop up"
              );

              if (zipCode === "") {
                dispatch({
                  type: "SET_ERROR_MESSAGE",
                  payload: true,
                });
                dispatch({
                  type: "SET_MESSAGE",
                  payload: "Please enter a zip code",
                });
              } else if (
                selectCorrectHrefForTheZip() === "javascript:void(0)"
              ) {
                dispatch({
                  type: "SET_ERROR_MESSAGE",
                  payload: true,
                });
                dispatch({
                  type: "SET_MESSAGE",
                  payload:
                    "Buy online currently not available in this zip code",
                });
                GA(
                  "Start_Quote_FAILED",
                  `ZIP: ${zipCode} - User clicked on Start Quote button`
                );
              }
            }}
          >
            <button
              onClick={(e) => {
                if (selectCorrectHrefForTheZip() === "javascript:void(0)") {
                  e.preventDefault();
                } else {
                  window.open(selectCorrectHrefForTheZip());
                }

                checkZipCodeValidation();
              }}
              className="start-quote-button"
            >
              Start Quote
            </button>
          </div>
          <br></br>
          <p
            onClick={() => {
              setIsGetAQuoteOpen(false);
              setIsContinuePreviousQuote(true);
              GA(
                "Previous_Quote",
                "User clicked on the continue previous quote inside the get a quote pop up"
              );
            }}
            style={{
              cursor: "pointer",
              marginBottom: "10px",
              textDecoration: "underline",
            }}
          >
            Continue Previous Quote
          </p>
        </form>
      </div>
    );
  };

  function numbersOnly(e) {
    var key = e.which || e.keyCode; //use event.which if it's truthy, and default to keyCode otherwise
    // Allow: backspace, delete, tab, and enter
    var controlKeys = [8, 9, 13];
    //for mozilla these are arrow keys
    //if ($.browser.mozilla) controlKeys = controlKeys.concat([37, 38, 39, 40]);

    // Ctrl+ anything or one of the conttrolKeys is valid
    var isControlKey =
      e.ctrlKey || controlKeys.join(",").match(new RegExp(key));
    if (isControlKey) {
      return;
    }
    // stop current key press if it's not a number
    if (!(48 <= key && key <= 57)) {
      e.preventDefault();
      return;
    }
  }

  return (
    <div className={isComponentLoaded ? "home-page" : "home-page-not-rendered"}>
      {continuePreviousQuoteComponent()}
      {getAQuoteComponent()}
      <div className="home-picture" style={{ height: isIE ? "500px" : "" }}>
        <div className="home-page-left-div">
          <div className="ribbon">
            <span>Buy online</span>
          </div>
          <p className="home-page-home-text">&nbsp;</p>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              target="_blank"
              autoComplete="false"
              rel="noreferrer"
            >
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (
                      selectCorrectHrefForTheZip() === "javascript:void(0)" ||
                      zipCode.length !== 5
                    ) {
                      GA(
                        "Start_Quote_FAILED",
                        `ZIP: ${zipCode} - zip code entered and rejected`
                      );
                      dispatch({
                        type: "SET_ERROR_MESSAGE",
                        payload: true,
                      });
                      dispatch({
                        type: "SET_MESSAGE",
                        payload: "Please enter a 5 digit zip code",
                      });

                      e.preventDefault();
                    } else {
                      GA(
                        "Start_Quote_SUCCESSFUL",
                        `ZIP: ${zipCode} - clicked on Start Quote button`
                      );
                      window.open(selectCorrectHrefForTheZip());
                    }

                    if (zipCode === "") {
                      dispatch({
                        type: "SET_ERROR_MESSAGE",
                        payload: true,
                      });
                      dispatch({
                        type: "SET_MESSAGE",
                        payload: "Please enter a 5 digit zip code",
                      });
                    } else if (
                      selectCorrectHrefForTheZip() === "javascript:void(0)"
                    ) {
                      checkZipCodeValidation();
                      dispatch({
                        type: "SET_ERROR_MESSAGE",
                        payload: true,
                      });
                      dispatch({
                        type: "SET_MESSAGE",
                        payload:
                          "Buy online currently not available in this zip code",
                      });
                      GA(
                        "Start_Quote_FAILED",
                        `ZIP: ${zipCode} - zip code entered and rejected`
                      );
                    }
                  }
                }}
                onKeyPress={(e) => {
                  numbersOnly(e);
                }}
                type="tel"
                // autoComplete="off"
                id="zip-input-box-1"
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
                maxLength="5"
                className="zip-input"
                placeholder="Enter zip code"
              ></input>
            </form>
            <div
              onClick={() => {
                GA("Start_Quote", "User clicked on Start Quote button");

                if (zipCode === "") {
                  dispatch({
                    type: "SET_ERROR_MESSAGE",
                    payload: true,
                  });
                  dispatch({
                    type: "SET_MESSAGE",
                    payload: "Please enter a zip code",
                  });
                } else if (
                  selectCorrectHrefForTheZip() === "javascript:void(0)"
                ) {
                  dispatch({
                    type: "SET_ERROR_MESSAGE",
                    payload: true,
                  });
                  dispatch({
                    type: "SET_MESSAGE",
                    payload:
                      "Buy online currently not available in this zip code",
                  });
                  GA(
                    "Start_Quote_FAILED",
                    `ZIP: ${zipCode} - zip code entered and rejected`
                  );
                }
              }}
              className="start-quote-button-container"
            >
              <>
                <button
                  onClick={(e) => {
                    checkZipCodeValidation();
                    if (selectCorrectHrefForTheZip() === "javascript:void(0)") {
                      e.preventDefault();
                    } else {
                      window.open(selectCorrectHrefForTheZip());
                    }
                  }}
                  className="start-quote-button"
                  disabled={zipCode.length !== 5 ? "disabled" : ""}
                >
                  Start Quote
                </button>
              </>
            </div>
          </div>
          <p className="banner-payment-options">
            <span
              style={{ textDecoration: "none" }}
              onClick={() => {
                setIsContinuePreviousQuote(true);
                GA("Previous_Quote", "User clicked on Continue previous quote");
              }}
            >
              Continue Previous Quote
            </span>
            &nbsp;| &nbsp;
            <a
              aria-label="Click to make a payment"
              onClick={() => {
                GA("Make_a_Payment", "User clicked on Make a payment");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                !isMobile
                  ? `${selectCorrectUrls().insuredPortal}/insurednew/`
                  : `${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`
              }
            >
              Make a Payment
            </a>
            <span>
              <span className="mobileOnly">
                {" "}
                <br></br> <br></br>{" "}
              </span>
            </span>
            <span className="desktopOnly">&nbsp;| &nbsp;</span>
            <a
              className="desktopOnly"
              aria-label="Click to  Renew your policy"
              onClick={() => {
                GA("Make_a_Payment", "User clicked on  Renew your policy");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                !isMobile
                  ? `${selectCorrectUrls().insuredPortal}/insurednew/`
                  : `${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`
              }
            >
              Renew your policy
            </a>
          </p>
          <a
            className="mobileOnly renew-policy"
            aria-label="Click to  Renew your policy"
            onClick={() => {
              GA("Make_a_Payment", "User clicked on  Renew your policy");
            }}
            // href="https://www.mypolicy.net/insurednew"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white", textDecoration: "none" }}
            href={
              !isMobile
                ? `${selectCorrectUrls().insuredPortal}/insurednew/`
                : `${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`
            }
          >
            Renew your policy
          </a>
          
          
          <div className="app-icons">
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/united-automobile-insurance/id868152074"
              rel="noreferrer"
              onClick={() => {
                GA(
                  "IOS_App_Download",
                  "User clicked on the IOS_App_Download icon on Banner"
                );
              }}
            >
              <LazyLoadImage
                alt="Download icon for iPhone"
                className="app-icons-download"
                src="/uaichome/pictures/iphone.png"
                effect="blur"
              ></LazyLoadImage>
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.phonegap.uaic&hl=en"
              rel="noreferrer"
              onClick={() => {
                GA(
                  "Android_App_Download",
                  "User clicked on the Android_App_Download icon on Banner"
                );
              }}
            >
              <LazyLoadImage
                alt="Download icon for Android"
                className="app-icons-download"
                src="/uaichome/pictures/android.png"
                effect="blur"
              ></LazyLoadImage>
            </a>
          </div>

          <p className="banner-payment-options ">
            <br></br>
            <a
              className=""
              aria-label="Click to File a claim"
              onClick={() => {
                GA("Claims_Home_menu_bar", "User clicked on claims button Home page banner menu");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target=""
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                `${selectCorrectUrls().insuredPortal}/insurednew/insured/claimuser/index`
                  
              }
            >
              <button className="start-quote-button claims-button">Claims</button>
            </a>
           <br></br>
            <a
              aria-label="Click to File a claim"
              onClick={() => {
                GA("Claims_Home_menu_bar", "User clicked on File a claim Home page banner menu");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target=""
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                 ` https://account-management-claims-uaic-gwcpprod-prod.api.delta4-andromeda.guidewire.net/account-management-claims/tpp-claims`     
              }
            >
              File a Claim
            </a>
            &nbsp;| &nbsp;
            <a
              aria-label="Click to Check Claim Status"
              onClick={() => {
                GA("Claims_Home_menu_bar", "User clicked on check claim status on banner menu");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target=""
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                   `https://www.uaig.net/uaichome/#/uaichome/claims`
              }
            >
           Claim Status
            </a>
            <span className="desktopOnly">  &nbsp;| &nbsp; </span>
            <a
            className="desktopOnly"
            aria-label="Click to Make Subro Payment"
              onClick={() => {
                GA("Claims_Home_menu_bar", "User clicked on subro payment on banner menu");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target=""
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                   `https://quote7.uaig.net/subro/#/subro`
              }
            >
              Make a Subro Payment
            </a>
            </p>
            <span className="mobileOnly" style={{marginTop:"-14px"}}>
                {" "}
                <br></br> <br></br>{" "}
              </span>
             
            <a
              className="mobileOnly"
              aria-label="Click to Make Subro Payment"
              onClick={() => {
                GA("Claims_Home_menu_bar", "User clicked on subro payment on banner menu");
              }}
              // href="https://www.mypolicy.net/insurednew"
              target=""
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              href={
                   `https://quote7.uaig.net/subro/#/subro`
              }
            >
              Make a Subro Payment
            </a>
       

        </div>

        <div className="slide-show">{<Slideshow />}</div>
      </div>{" "}
      {isIE ? (
        <></>
      ) : (
        <div className="waves-background">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              {/* <use
              xlinkHref="#gentle-wave"
              x="148"
              y="0"
              fill="rgba(255,255,255,0.7"
            /> */}

              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      )}
      <div className="grid-container">
        <div className="grid-item" id="MOBILE-ONLY">
          <a
            onClick={() => {
              GA(
                "Make_a_Payment",
                "User clicked on Make_a_Payment Button home screen"
              );
            }}
            // href="https://www.mypolicy.net/insurednew"
            target="_blank"
            className="make_a_payment"
            style={{ color: "white", textDecoration: "none" }}
            href={`${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`}
          >
            <h2 className="blue-color">Make a Payment</h2>
          </a>
        </div>
        <div className="grid-item" id="MOBILE-ONLY">
          <a
            onClick={() => {
              GA(
                "File_a_Claim",
                "User clicked on File a claim button home screen"
              );
            }}
            // href="https://www.mypolicy.net/insurednew"
            target="_blank"
            className="make_a_payment"
            style={{ color: "white", textDecoration: "none" }}
            href={`${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`}
          >
            <h2 className="blue-color">File a Claim</h2>
          </a>
        </div>

        <div
          onClick={() => {
            dispatch({
              type: "OPEN_LOGIN_MENU",
            });

            setTimeout(() => {
              document.getElementById("agent_portal_radio").click();
            }, 100);

            GA("Agent_login", "User clicked on the Agents Login Card");
          }}
          className="grid-item"
        >
          <h2 className="blue-color">Agents Login</h2>
          <LazyLoadImage
            alt="Icon of an agent with headphones on"
            className="grid-item-picture"
            src="/uaichome/pictures/agents.png"
            effect="blur"
          ></LazyLoadImage>
          <div>
            <button className="home-page-card-button">
              <i
                style={{ marginRight: "10px" }}
                className="fas fa-sign-in-alt"
              ></i>
              Login
            </button>
          </div>
        </div>
        <div
          onClick={() => {
            dispatch({
              type: "OPEN_LOGIN_MENU",
            });
            setTimeout(() => {
              document.getElementById("customer_portal_radio").click();
            }, 100);

            GA("Customer_login", "User clicked on the Customer Login Card");
          }}
          className="grid-item"
        >
          <h2 className="blue-color">Existing Customers</h2>
          <LazyLoadImage
            alt="Customer login icon"
            className="grid-item-picture"
            src="/uaichome/pictures/Customer.png"
            effect="blur"
          ></LazyLoadImage>
          <div>
            <button className="home-page-card-button">
              <i
                style={{ marginRight: "10px" }}
                className="fas fa-sign-in-alt"
              ></i>
              Continue
            </button>
          </div>
        </div>
        <Link
          style={{
            textDecoration: "none",
          }}
          to="/uaichome/claims"
          className="grid-item"
          onClick={() => {
            GA("File_A_Claim", "User clicked on the File a Claim Card");
          }}
        >
          <div>
            <h2 className="blue-color">Claims Portal</h2>
            <LazyLoadImage
              alt="One car crashing into another from the side"
              className="grid-item-picture"
              src="/uaichome/pictures/fileaclaim.png"
              effect="blur"
            ></LazyLoadImage>
            <button className="home-page-card-button">
              <i
                style={{ marginRight: "10px" }}
                className="fas fa-car-crash"
              ></i>
              Continue
            </button>
          </div>
        </Link>
        <a
          onClick={() => {
            GA("Lienholder_Search", "User clicked on the lienholder card");
          }}
          style={{
            textDecoration: "none",
          }}
          href={
            !isMobile
              ? `${selectCorrectUrls().insuredPortal}/insurednew`
              : `${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`
          }
          className="grid-item"
        >
          <h2 className="blue-color">Make a Payment</h2>
          <LazyLoadImage
            alt="Calculator with a coin with the dollar logo on it"
            className="grid-item-picture"
            src="/uaichome/pictures/getaquote.png"
            effect="blur"
          ></LazyLoadImage>
          <button className="home-page-card-button">
            <i
              style={{ marginRight: "10px" }}
              className="fas fa-hand-holding-usd"
            ></i>
            Continue
          </button>
        </a>
        <a
          onClick={() => {
            GA("Make a subro payment", "User Clicked on Make a subro Payment");
          }}
          style={{
            textDecoration: "none",
          }}
          // href={
          //   !isMobile
          //     ? `${selectCorrectUrls().insuredPortal}/insurednew`
          //     : `${selectCorrectUrls().insuredPortal}/mobile/?rfm=yes`
          // }
          href={
           `https://quote7.uaig.net/subro/#/subro`
          }
          className="grid-item"
          id="MOBILE-ONLY"
        >
          <h2 className="blue-color">Make a Subro Payment</h2>
          <LazyLoadImage
            alt="Calculator with a coin with the dollar logo on it"
            className="grid-item-picture"
            src="/uaichome/pictures/getaquote.png"
            effect="blur"
          ></LazyLoadImage>
          <button className="home-page-card-button">
            <i
              style={{ marginRight: "10px" }}
              className="fas fa-hand-holding-usd"
            ></i>
            Continue
          </button>
        </a>
        <Link
          onClick={() => {
            GA("Lienholder_Search", "User clicked on the lienholder card");
          }}
          style={{
            textDecoration: "none",
          }}
          to="/uaichome/leinholder"
          className="grid-item"
        >
          <div>
            <h2 className="blue-color">Lienholder Search</h2>
            <LazyLoadImage
              alt="Icon of a building resebling a bank with a dollar sign on top of it"
              className="grid-item-picture"
              src="/uaichome/pictures/download.png"
              effect="blur"
            ></LazyLoadImage>
            <div>
              <button className="home-page-card-button">
                <i
                  style={{ marginRight: "10px" }}
                  className="fas fa-search"
                ></i>
                Search
              </button>
            </div>
          </div>
        </Link>
        <Link
          style={{
            textDecoration: "none",
          }}
          to="/uaichome/becomeagent"
          className="grid-item"
          onClick={() => {
            GA("Become_An_Agent", "User clicked on the Become an Agent Card");
          }}
        >
          <h2 className="blue-color">Become an Agent</h2>
          <LazyLoadImage
            alt="Notebook with a little pencil writing on it"
            className="grid-item-picture"
            src="/uaichome/pictures/BAA.png"
            effect="blur"
          ></LazyLoadImage>
          <button className="home-page-card-button">
            <i style={{ marginRight: "10px" }} className="fas fa-pen"></i>
            Continue
          </button>
        </Link>
      </div>
      <div className="home-page-about">
        <div className="about-us-info">
          <div className="about-us-info-div">
            <div className="about-us-column-1">
              <h2>Welcome to UAIC</h2>
              <hr></hr>
              <br></br>
              <p>
                United Automobile Insurance Company (UAIC) was founded in 1989
                and is headquartered in Miami Gardens, Florida. The company
                currently operates in multiple states and is structured to
                provide a high quality and low cost automobile insurance
                product. UAIC is family owned and is one of the largest
                privately held property and casualty insurance companies in the
                United States.
              </p>
              <br></br>
              <p>
                In 1998, UAIC had operations in two states, Illinois and
                Florida. Since then, the company has expanded its operations to
                multiple states and is broadening to serve different segments of
                the market. The key to our growth and success is a commitment to{" "}
                <strong>providing quality service </strong>
                to our agents and customers, disciplined underwriting, and
                strategic claims handling.
              </p>
              <br></br>
              <p>
                The company’s advancements in technology have been a catapult to
                our evolution. Our web technology and innovation is rated year
                after year as the most user-friendly and dependable by our
                agents. Our ongoing efforts to invest substantial resources in
                personnel and technology are the foundation to our promise of
                assuring maximum efficiency and specialized operations in the
                industry.
              </p>
              <br></br>
              <p>
                We will continue to strive to be the leading automobile
                insurance company in the industry. We are proud that our
                independent agents and customers have recognized our dedication
                by making UAIC the market leader in every state where we conduct
                business.
              </p>
              <br></br>
            </div>
            <div className="about-us-column-2">
              <LazyLoadImage
                className="about-us-picture"
                alt="Entrance of the company"
                src="/uaichome/pictures/aboutimage3.png"
                effect="blur"
              ></LazyLoadImage>
              <br></br>
              <br></br>
              <h2 className="blue-color">
                <i
                  style={{ marginRight: "1rem", marginLeft: "1rem" }}
                  className="fas fa-hand-holding-usd"
                ></i>
                What we Offer
              </h2>
              <hr></hr>
              <ul>
                <li>Automobile Insurance.</li>
                <li>SR22 Insurance</li>
                <li>FR44 Insurance</li>
                <li>Non-owner’s policy</li>
                <li>Commercial Auto Insurance.</li>
                <li>
                  Products & services tailored to the needs of the independent
                  agent.
                </li>
                <li>Low rates & money-saving discounts.</li>
                <li>Personalized coverage options.</li>
                <li>Expert advice from your local agent.</li>
              </ul>
            </div>
          </div>
          <div className="our-mission-div">
            <h2 className="blue-color">
              <i
                style={{ marginRight: "1rem" }}
                className="fas fa-flag-checkered"
              ></i>
              Our Mission
            </h2>
            <hr style={{ marginBottom: "15px" }}></hr>
            <p>
              Our mission at United Automobile Insurance Company is to provide a
              quality, affordable, and reliable insurance product to a wide
              range sector of the American population. Through constant analysis
              and investments in technology and infrastructure, UAIC will
              continue to grow, while never losing sight of the underwriting
              discipline and claims philosophy that empowers our customer
              satisfaction.
            </p>
          </div>
        </div>
      </div>
      <div className="video-carousel-container">
        <h2>How to's:</h2>
        <VideosCarousel />
      </div>
      <div className="home-page-become-an-agent">
        <div>
          <LazyLoadImage
            alt="Agent wearing a suit smiling while holding a mobile phone to his ear"
            className="home-page-become-an-agent-picture"
            src="/uaichome/pictures/agent.png"
            effect="blur"
          ></LazyLoadImage>
        </div>
        <div className="home-page-become-an-agent-div">
          <h1 className="blue-color" style={{ fontSize: "40px" }}>
            Become an Agent!
          </h1>
          <p>
            Save your customers money by offering our wide range of policies. To
            get started, select your state below.
          </p>
          <label
            aria-labelledby="become-an-agent-select-state"
            aria-hidden="true"
            for="become-an-agent-select-state"
          >
            Select a State:
          </label>
          <select
            name="become-an-agent-select-state"
            aria-label="become-an-agent-select-state"
            onChange={(e) => {
              openInNewTab(e.target.value);
            }}
            onClick={() => {
              GA(
                "BAA_Dropdown",
                "User clicked on the BAA_Dropdown icon on homescreen"
              );
            }}
            id="become-an-agent-select-state"
            style={{ border: "1px solid rgb(200,200,200)" }}
            className="select-state-select"
          >
            <option value="" disabled selected hidden>
              - Select a State -
            </option>

            <option value="https://baa.uaig.net/fl/index.php">Florida</option>
            {/* <option value="https://baa.uaig.net/ga/index.php">Georgia</option> */}
            <option value="https://baa.uaig.net/tx/index.php">Texas</option>
          </select>
        </div>
        
      </div>
      <div className="home-page-become-an-agent">
        <div>
          <LazyLoadImage
            alt="Agent wearing a suit smiling while holding a mobile phone to his ear"
            className="home-page-become-an-agent-picture"
            src="/uaichome/pictures/agent.png"
            effect="blur"
          ></LazyLoadImage>
        </div>
        <div className="home-page-become-an-agent-div">
          <h1 className="blue-color" style={{ fontSize: "40px" }}>
            Become an Agent!
          </h1>
          <p>
            Save your customers money by offering our wide range of policies. To
            get started, select your state below.
          </p>
          <label
            aria-labelledby="become-an-agent-select-state"
            aria-hidden="true"
            for="become-an-agent-select-state"
          >
            Select a State:
          </label>
          <select
            name="become-an-agent-select-state"
            aria-label="become-an-agent-select-state"
            onChange={(e) => {
              openInNewTab(e.target.value);
            }}
            onClick={() => {
              GA(
                "BAA_Dropdown",
                "User clicked on the BAA_Dropdown icon on homescreen"
              );
            }}
            id="become-an-agent-select-state"
            style={{ border: "1px solid rgb(200,200,200)" }}
            className="select-state-select"
          >
            <option value="" disabled selected hidden>
              - Select a State -
            </option>

            <option value="https://baa.uaig.net/fl/index.php">Florida</option>
            {/* <option value="https://baa.uaig.net/ga/index.php">Georgia</option> */}
            <option value="https://baa.uaig.net/tx/index.php">Texas</option>
          </select>
        </div>
        
      </div>
      <div className="home-page-become-an-agent">
        <div>
          <LazyLoadImage
            alt="Agent wearing a suit smiling while holding a mobile phone to his ear"
            className="home-page-become-an-agent-picture"
            src="/uaichome/pictures/agent.png"
            effect="blur"
          ></LazyLoadImage>
        </div>
        <div className="home-page-become-an-agent-div">
          <h1 className="blue-color" style={{ fontSize: "40px" }}>
            Become an Agents!
          </h1>
          <p>
            Save your customers money by offering our wide range of policies. To
            get started, select your state below.
          </p>
          <label
            aria-labelledby="become-an-agent-select-state"
            aria-hidden="true"
            for="become-an-agent-select-state"
          >
            Select a State:
          </label>
          <select
            name="become-an-agent-select-state"
            aria-label="become-an-agent-select-state"
            onChange={(e) => {
              openInNewTab(e.target.value);
            }}
            onClick={() => {
              GA(
                "BAA_Dropdown",
                "User clicked on the BAA_Dropdown icon on homescreen"
              );
            }}
            id="become-an-agent-select-state"
            style={{ border: "1px solid rgb(200,200,200)" }}
            className="select-state-select"
          >
            <option value="" disabled selected hidden>
              - Select a State -
            </option>

            <option value="https://baa.uaig.net/fl/index.php">Florida</option>
            {/* <option value="https://baa.uaig.net/ga/index.php">Georgia</option> */}
            <option value="https://baa.uaig.net/tx/index.php">Texas</option>
          </select>
        </div>
        
      </div>
      
    </div>
  );
};

export default HomePage;
