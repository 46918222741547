import React, { useState, useEffect } from "react";
import "./jobFairs.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const JobFairs = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div className={isComponentLoaded ? "JobFairs" : "JobFairs-not-rendered"}>
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          alt="A person trying to shake your hand"
          src="/uaichome/pictures/jobfairs.png"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Job Fairs</h2>
        <hr></hr>
        <div>
          <p>
            Find out when United Automobile Insurance Company will visit your
            area and select a state for more information. We only list areas
            with scheduled events, so please check back soon if you don't see
            your area on the list today.
          </p>
          <br></br>
          <p style={{ fontStyle: "italic" }}>
            Note: We currently do not have any job fairs scheduled. So please
            check back soon.
          </p>
          <br></br>
          <br></br>
          <h3>Tips for Participating in Job Fairs</h3>
          <hr></hr>
          <ul>
            <li>
              <strong>First impressions are lasting ones</strong> so treat the
              career fairs that you are attending like a job interview. Come
              dressed for success in conservative attire, with a winning
              attitude, and ready to answer probing questions.
            </li>
            <br></br>
            <li>
              <strong>Periodically attend these events</strong> even when you
              are not necessarily looking for a job to see what opportunities
              are out there, and to gain a perspective on where you fit in the
              job marketplace.
            </li>
            <br></br>
            <li>
              <strong>Be prepared</strong> by bringing the following: a pen,
              note pad and stack of resumes. You should also bring a portfolio
              or carryall that has easily accessible storage areas. Wear
              comfortable, professional-looking shoes designed for standing long
              periods of time.
            </li>
            <br></br>
            <li>
              <strong>
                Your resume should be scannable, short and professional
              </strong>
              on white paper that is free of graphics, photographs or fancy
              print styles, but also containing larger margins for interviewer
              notes.
            </li>
            <br></br>
            <li>
              If they distribute name tags at the conference or job fair, by all
              means use them.
            </li>
            <br></br>
            <li>
              <strong>Arrive early</strong> to avoid having to stand in long
              lines. Large companies with high profiles will have the longest
              lines.
            </li>
            <br></br>
            <li>
              <strong>Be prepared to assertively introduce yourself</strong>{" "}
              giving your best handshake, showing enthusiasm and making eye
              contact with the interviewer. Be concise, polite and direct, as
              you only have brief period of time to obtain the information that
              you need and to make an impression.
            </li>
            <br></br>
            <li>
              <strong>
                Finding out about position needs, company culture and diversity.
              </strong>
              These questions will help you figure out if the company is a good
              match for you. Use the information that you obtain from your
              company research, and the questions that you ask to sell your
              skills that address their needs.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default JobFairs;
