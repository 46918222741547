import React, { useEffect, useState } from "react";
import "./aboutUs.css";

const AboutUs = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div className={isComponentLoaded ? "about-us" : "about-us-not-rendered"}>
      <div className="about-uaic-box">
        <i style={{ marginLeft: "2rem" }} className="fas fa-info fa-2x"></i>
        <h2 style={{ marginLeft: "1rem" }}>About UAIC </h2>
      </div>
      <div className="about-us-info">
        <div className="about-us-info-div">
          <div className="about-us-column-1">
            <h2>Welcome to UAIC</h2>
            <hr></hr>
            <br></br>
            <p>
              United Automobile Insurance Company (UAIC) was founded in 1989 and
              is headquartered in Miami Gardens, Florida. The company currently
              operates in multiple states and is structured to provide a high
              quality and low cost automobile insurance product. UAIC is family
              owned and is one of the largest privately held property and
              casualty insurance companies in the United States.
            </p>
            <br></br>
            <p>
              In 1998, UAIC had operations in two states, Illinois and Florida.
              Since then, the company has expanded its operations to multiple
              states and is broadening to serve different segments of the
              market. The key to our growth and success is a commitment to{" "}
              <strong>providing quality service </strong>
              to our agents and customers, disciplined underwriting, and
              strategic claims handling.
            </p>
            <br></br>
            <p>
              The company’s advancements in technology have been a catapult to
              our evolution. Our web technology and innovation is rated year
              after year as the most user-friendly and dependable by our agents.
              Our ongoing efforts to invest substantial resources in personnel
              and technology are the foundation to our promise of assuring
              maximum efficiency and specialized operations in the industry.
            </p>
            <br></br>
            <p>
              We will continue to strive to be the leading automobile insurance
              company in the industry. We are proud that our independent agents
              and customers have recognized our dedication by making UAIC the
              market leader in every state where we conduct business.
            </p>
          </div>
          <div className="about-us-column-2">
            <img
              className="about-us-picture"
              alt="Entrance of the company"
              src="/uaichome/pictures/aboutimage3.png"
            ></img>
            <br></br>
            <br></br>
            <h2 className="blue-color">
              <i
                style={{ marginRight: "1rem", marginLeft: "1rem" }}
                className="fas fa-hand-holding-usd"
              ></i>
              What we Offer
            </h2>
            <hr></hr>
            <ul>
              <li>Automobile Insurance.</li>
              <li>SR22 Insurance</li>
              {/* <li>FR44 Insurance</li>  Removed Due to Joanna */}
              <li>Non-owner’s policy</li>
              <li>Commercial Auto Insurance.</li>
              <li>
                Products &amp; services tailored to the needs of the independent
                agent.
              </li>
              <li>Low rates &amp; money-saving discounts.</li>
              <li>Personalized coverage options.</li>
              <li>Expert advice from your local agent.</li>
            </ul>
          </div>
        </div>
        <div className="our-mission-div">
          <h2 className="blue-color">
            <i
              style={{ marginRight: "1rem" }}
              className="fas fa-flag-checkered"
            ></i>
            Our Mission
          </h2>
          <hr style={{ marginBottom: "15px" }}></hr>
          <p>
            Our mission at United Automobile Insurance Company is to provide a
            quality, affordable, and reliable insurance product to a wide range
            sector of the American population. Through constant analysis and
            investments in technology and infrastructure, UAIC will continue to
            grow, while never losing sight of the underwriting discipline and
            claims philosophy that empowers our customer satisfaction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
