import { useCallback, useEffect, useState } from "react";

const useScapePressed = () => {
  const [pressedFlag, setPressedFlag] = useState(false);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setPressedFlag(!pressedFlag);
      }
    },
    [pressedFlag]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  return pressedFlag;
};

export default useScapePressed;
