// build commit

const selectCorrectUrls = () => {
  const isProd = true; //false

  if (isProd) {
    return {
      insuredPortal: "https://mypolicy.uaig.net",
      claimsStatus: "https://claimstatus.uaig.net",
      quotePortal: "https://quote7.uaig.net",
      quotePortalold:"https://quote.uaig.net",
      florida: "https://fl.uaig.net",
      texas: "https://tx.uaig.net",
      georgia: "https://ga.uaig.net",
      googleAnalytics: "G-WDD83JSYKM",
      qa7: "https://quote7.uaig.net",
    };
  } else {
    return {
      insuredPortal: "https://quoteqa.uaig.net",
      claimsStatus: "https://claimstatus.uaig.net",
      quotePortal: "https://quoteqa7.uaig.net",
      quotePortalold:"https://quoteqa.uaig.net",
      florida: "https://fl.uaig.net", //this would be https://flqa.uaig.net but the server is sending this one back instead
      texas: "https://txqa.uaig.net",
      georgia: "https://ga.uaig.net",
      googleAnalytics: "G-YX975GZKF9", // New = G-YX975GZKF9 , old =  UA-199321219-1
      qa7:"https://quoteqa7.uaig.net",
    };
  }
};

export default selectCorrectUrls;
