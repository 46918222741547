import React, { useEffect, useState } from "react";
import "./siteRequirements.css";

const SiteRequirements = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  const careersSideNavigation = () => {
    return (
      <div className="careers-navigation">
        <div style={{ position: "sticky", top: "100px" }}>
          <h4>UAIC Site Requirements</h4>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                scrollIntoComponent("software");
              }}
              className="careers-navigation-link-item"
            >
              Software, Tools, and Tips
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                scrollIntoComponent("Browsers");
              }}
              className="careers-navigation-link-item"
            >
              Browsers
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                scrollIntoComponent("operating-systems");
              }}
              className="careers-navigation-link-item"
            >
              Operating Systems
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                scrollIntoComponent("plug-ins");
              }}
              className="careers-navigation-link-item"
            >
              Plug-Ins
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                scrollIntoComponent("cookies");
              }}
              className="careers-navigation-link-item"
            >
              Cookies
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                scrollIntoComponent("javascript");
              }}
              className="careers-navigation-link-item"
            >
              Javascript
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                scrollIntoComponent("downloads");
              }}
              className="careers-navigation-link-item"
            >
              Downloads
            </p>
          </div>
        </div>
      </div>
    );
  };

  const scrollIntoComponent = (componentId) => {
    const yOffset = -100;
    const element = document.getElementById(componentId);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div
      className={
        isComponentLoaded
          ? "site-requirements"
          : "site-requirements-not-rendered"
      }
    >
      <div className="site-requirements-box">
        <h2 className="color-blue" style={{ marginLeft: "2rem" }}>
          UAIC Site Requirements
        </h2>
      </div>
      <div className="site-map-div">
        <div>{careersSideNavigation()}</div>
        <div className="component-to-display-div-site-req">
          <div>
            <h3 id="software">Software, Tools, and Tips</h3>
            <hr></hr>
            <p>
              Our website uses technology to create a more user-friendly
              experience. The result is a smarter, more easily navigated site.
              We hope you enjoy UAIC.com and that you encounter no technical
              difficulties while visiting the site.
            </p>
            <br></br>
            <br></br>
            <h3 id="Browsers">Browsers:</h3>
            <hr></hr>
            <p>
              Although our site supports many popular browsers, UAIC.com is best
              viewed on Microsoft Internet Explorer 7.x or higher. It can also
              be viewed on Safari, and Firefox 2 or higher. Certain content may
              require plug-ins to be installed. And we also recommend that your
              browser has the latest updates or service packs.
            </p>
            <br></br>
            <br></br>
            <h3 id="operating-systems">Operating Systems:</h3>
            <hr></hr>
            <p>
              UAIC.com is supported in Windows 2000, XP and Vista. It is also
              supported on Mac OS 9 and OSX Panther.
            </p>
            <br></br>
            <ul>
              <li>
                <h3>PC:</h3>
                <p>
                  Windows 2000, XP and Vista Browsers: Microsoft Internet
                  Explorer 6.x or later, Mozilla Firefox 2.x or later, Safari
                  2.x or later 16-Bit Minimum Monitor Color Depth (32-bit color
                  depth recommended) 1024x768 Resolution 128 MB RAM (256 MB
                  recommended) 800MHz CPU (1GHz recommended) Plug-ins: Flash 9,
                  QuickTime 6, Acrobat Reader 7 Minimum connection speed: 200K
                  (300K Recommended)
                </p>
              </li>
              <br></br>
              <li>
                <h3>Mac</h3>
                <p>
                  Mac OS 9.X, Mac OSX (Panther) Browsers: Mac OS9: IE 6.x / Mac
                  OSX Panther: IE 6.x, Safari 2.x, FireFox 2.x 16-Bit Minimum
                  Monitor Color Depth (32-bit color depth recommended) 1024x768
                  Resolution 128 MB RAM (256 MB recommended) 800MHz CPU (1GHz
                  recommended) Plug-ins: Flash 9, QuickTime 6, Acrobat Reader 7
                  Minimum connection speed: 200K (300K Recommended)
                </p>
              </li>
            </ul>

            <br></br>
            <br></br>
            <h3>Screen Resolution</h3>
            <hr></hr>
            <p>
              We recommend configuring your monitor to 1024 x 768 for optimal
              viewing. Settings vary depending on the system you are using. To
              set your monitor, follow the instructions below.
            </p>
            <br></br>
            <ul>
              <li>
                <h3>PC:</h3>
                <p>
                  Go to the START menu, choose "Settings" and select "Control
                  Panel." Double-click DISPLAY and select "Settings." Drag the
                  slide bar in the SCREEN area until the numbers read 1024 x
                  768. Under COLORS, select "High Color" (32 bit) Click "OK."
                </p>
              </li>
              <br></br>
              <li>
                <h3>Mac</h3>
                <p>
                  Under the "Apple" menu, select "Control Panels" and then
                  "Monitors." On the Monitor setting, under "Colors," select
                  "Thousands" and 1024 x 768. When provided the option, we
                  recommend restarting your computer with the new color
                  settings. For more detailed instructions, please consult the
                  documentation that came with your monitor.
                </p>
              </li>
            </ul>

            <br></br>
            <br></br>
            <h3 id="plug-ins">Plug-Ins</h3>
            <hr></hr>
            <p>
              The UAIC site uses Flash to provide interactive content. If this
              and other applications are not already installed on your computer,
              we recommend downloading the following plug-ins.
            </p>
            <br></br>
            <p>
              <strong>Download</strong>
            </p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://get.adobe.com/reader/"
              >
                Adobe Acrobat Reader
              </a>{" "}
              to read PDF documents
            </p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://get.adobe.com/flashplayer/"
              >
                Adobe Flash PlayerFlash
              </a>
              to view animation graphics, demos & tutorials
            </p>
            <br></br>
            <br></br>
            <h3 id="cookies">Cookies</h3>
            <hr></hr>
            <p>
              Please set your Internet preferences to enable cookies. Cookies
              are small text files containing information that identifies
              returning visitors exclusively for the duration of their visit.
              The information provided by cookies helps us to tailor content
              specifically to your needs. Our strict security guidelines
              guarantee that no personal data is stored during this process. See
              our Privacy Policy for more information. To enable cookies, follow
              the instructions below:
            </p>
            <br></br>
            <ul>
              <li>
                <h4>Internet Explorer</h4>
                <p>
                  Windows IE 6.x Browser: Select Tools Select Internet Options
                  Select the Privacy tab Select Advanced Deselect override
                  automatic cookie handling button Click on the OK button at the
                  bottom of the screen Click OK to exit.
                </p>
                <br></br>
                <p>
                  Select "Preferences..." from the "Explorer" menu Click
                  "Cookies" under the "Receiving Files" heading in the scrolling
                  pane In the "When receiving cookies:" pull-down menu, select
                  "Never ask" Click "OK" button to close "Preferences" window.
                </p>
              </li>
              <br></br>
              <li>
                <h4>Firefox</h4>
                <p>Windows and Mac systems:</p>
                <br></br>
                <p>
                  Select Tools {">"} Options (Windows) or Firefox-{">"}
                  Preferences (Mac) Click "Privacy" in the left pane Check
                  "Allow sites to set cookies" under the "Cookies" section Click
                  "OK" button to close "Preferences" window
                </p>
              </li>
            </ul>
            <br></br>
            <br></br>
            <h3 id="javascript">Javascript</h3>
            <hr></hr>
            <p>
              To optimize use of this Website, please make sure that
              JavaScriptâ„¢ is activated in your browser's settings. We use this
              technology to best display the individual pages with different
              screen resolutions and for navigation. To enable JavaScript,
              follow the instructions below:
            </p>
            <ul>
              <li>
                <h4>Internet Explorer</h4>
                <ol>
                  <li>Windows IE 6.x Browser:</li>
                  <li>Select "Internet Options" from the "Tools" menu</li>
                  <li>Select the "Security" tab and click "Custom Level"</li>
                  <li>
                    Scroll down to the "Scripting" section and select "Enable"
                    under "Active Scripting"
                  </li>
                  <li>Click OK and "Yes" at the prompt</li>
                  <li>Click OK to exit</li>
                  <li>Close all browsers windows and relaunch IE</li>
                </ol>
              </li>
              <br></br>
              <li>
                <h4>Safari:</h4>
                <ol>
                  <li>Select "Preferences..." from the "Explorer" menu</li>
                  <li>Click the arrow next to "Web Browser"</li>
                  <li>Click "Web Content"</li>
                  <li>Under "Active Content" check "Enable Scripting"</li>
                  <li>Click "OK" button to close "Preferences" window.</li>
                </ol>
              </li>
              <br></br>
              <li>
                <h4>Firefox:</h4>
                <ol>
                  <li>Windows and Mac systems:</li>
                  <li>
                    Select "Options" from the "Tools" menu (Windows) or
                    "Preferences" from the "Firefox" menu
                  </li>
                  <li>
                    (Mac) Select the "Content" tab Scroll down to the
                    "Scripting" section and select "Enable" under "Active
                    Scripting"
                  </li>
                  <li>Click the checkbox next to "Enable JavaScript"</li>
                  <li>Click OK to exit</li>
                </ol>
              </li>
            </ul>
            <br></br>
            <br></br>
            <h3 id="downloads">Downloads</h3>
            <hr></hr>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="http://windows.microsoft.com/en-us/internet-explorer/download-ie"
                  rel="noreferrer"
                >
                  Internet Explorer
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="http://get.adobe.com/flashplayer/"
                  rel="noreferrer"
                >
                  Adobe Flash Player
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="http://www.apple.com/quicktime/"
                  rel="noreferrer"
                >
                  QuickTime
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="http://www.mozilla.org/en-US/firefox/new/"
                  rel="noreferrer"
                >
                  Mozilla Firefox
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="http://get.adobe.com/reader/"
                  rel="noreferrer"
                >
                  Adobe Acrobat Reader
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteRequirements;
