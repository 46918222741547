import React from "react";
import "./footer.css";
import GA from "../functions/GA";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const Footer = () => {
  const dispatch = useDispatch();

  const scrollToTop = (paddingTop) => {
    window.scrollTo({
      top: paddingTop,
      behavior: "smooth",
    });
  };

  const getCurrentYear = () => {
    const date = new Date();

    return date.getFullYear();
  };

  return (
    <div>
      <div id="DigiCertClickID_7E0EEXXz"></div>
      <BrowserView>
        <div className="footer footer-desktop">
          <div className="footer-div">
            <div className="logo-and-social-media">
              <img
                alt="Company logo"
                className="footer-logo"
                src="/uaichome/pictures/United_transparent_white.png"
              />
              <h2>Stay connected with us</h2>
              <div className="icons">
                {/* <a
                  
                  style={{ display: "none" }}
                  aria-label="Link to facebook , inside of Footer "
                >
                  <i className="fab fa-facebook-square fa-2x icon-footer"></i>
                </a> */}
                <a
                  style={{ color: "white" }}
                  href="https://www.instagram.com/united_automobile_insurance/?hl=en"
                  aria-label="Link to Instagram , inside of Footer "
                >
                  <i className="fab fa-instagram fa-2x icon-footer"></i>
                </a>
                <a
                  style={{ color: "white" }}
                  href="https://www.linkedin.com/company/unitedautomobileinsurance"
                  aria-label="Link to Linkedin , inside of Footer "
                >
                  <i className="fab fa-linkedin fa-2x icon-footer"></i>
                </a>
              </div>
            </div>

            <div className="footer-first-column">
              <ul
                className={
                  window.document.documentMode ? "ul-list-ie" : "ul-list"
                }
              >
                <li>
                  <Link
                    style={{
                      color: "rgb(100, 100, 255)",
                      textDecoration: "none",
                    }}
                    className="footer-links"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                    to="/uaichome"
                  >
                    UAIC HOMEPAGE
                  </Link>
                </li>
                <li>
                  <a
                    className="footer-links"
                    href="https://mypolicy.uaig.net/insurednew/"
                  >
                    Policyholder Portal
                  </a>
                </li>
                <li>
                  <p
                    onClick={() => {
                      dispatch({
                        type: "OPEN_LOGIN_MENU",
                      });

                      setTimeout(() => {
                        document.getElementById("agent_portal_radio").click();
                      }, 100);

                      GA(
                        "Agent_login",
                        "User clicked on the Agents Login Card"
                      );
                    }}
                    className="footer-links"
                  >
                    UAIC Agents Log In
                  </p>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/becomeAgent"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Become an Agent</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/aboutUs"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p
                      style={{ color: "rgb(100, 100, 255)" }}
                      className="footer-links"
                    >
                      ABOUT UAIC
                    </p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/site-map"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Site Map</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/site-requirements"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Site Requirements</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/accessibility"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Accessibility Statement</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/privacy-policy"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Privacy Policy</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/insurance"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p
                      style={{ color: "rgb(100, 100, 255)" }}
                      className="footer-links"
                    >
                      INSURANCE
                    </p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/contact"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p
                      style={{ color: "rgb(100, 100, 255)" }}
                      className="footer-links"
                    >
                      CONTACT UAIC
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-second-column">
              <ul
                className={
                  window.document.documentMode ? "ul-list-ie" : "ul-list"
                }
              >
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Careers",
                      });
                      scrollToTop(100);
                    }}
                  >
                    <p
                      style={{ color: "rgb(100, 100, 255)" }}
                      className="footer-links"
                    >
                      CAREERS
                    </p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Careers",
                      });
                      scrollToTop(100);
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Apply Online</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Benefits",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Benefits</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Diversity",
                      });
                      scrollToTop(100);
                    }}
                    to="/uaichome/careers"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <p className="footer-links">Diversity</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "FAQ",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">FAQ</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Job_Fairs",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Job Fairs</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Accounting",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Accounting</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Claims",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Claims Adjuster</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Corporate_&_Executive",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Corporate & Executive</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Information_Technology",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Information Technology</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      dispatch({
                        type: "SET_SELECTED_CAREERS_TAB",
                        payload: "Underwriting",
                      });
                      scrollToTop(100);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/careers"
                  >
                    <p className="footer-links">Underwriting</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-third-column">
              <a
                rel="noreferrer"
                target="blank"
                href="https://www.essentialaccessibility.com/uaic?utm_source=uaichomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=uaic"
              >
                <img
                  alt="Company logo"
                  className="footer-image"
                  style={{ height: "50px" }}
                  src="/uaichome/pictures/english-ea-icon.png"
                />
              </a>
              <br></br>

              <br></br>
              <p style={{ color: "rgb(100, 100, 255)" }}>
                Managing Your Policy Just Got Easier.
              </p>
              <p style={{ color: "rgb(100, 100, 255)" }}>
                Download Our Mobile App Today!
              </p>
              <br></br>
              <div style={{ display: "flex" }}>
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/united-automobile-insurance/id868152074"
                  rel="noreferrer"
                >
                  <img
                    alt="Download iPhone app"
                    className="footer-image"
                    style={{ height: "40px" }}
                    src="/uaichome/pictures/iphone.png"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.phonegap.uaic&hl=en"
                  rel="noreferrer"
                >
                  <img
                    alt="Download android app"
                    className="footer-image"
                    style={{ height: "40px" }}
                    src="/uaichome/pictures/android.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="geo-trust-div footer-desktop">
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://seal.digicert.com/seals/popup/?tag=7E0EEXXz&url=www.uaig.net&lang=en"
            >
              <img
                src="/uaichome/pictures/GeoTrust.png"
                alt="GeoTrust Seal for United Automobile"
              ></img>
            </a>
            <p>
              Copyright © {getCurrentYear()} United Automobile Insurance Company
            </p>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="footer footer-mobile">
          <div>
            <img
              alt="Company logo"
              className="footer-logo"
              src="/uaichome/pictures/United_transparent_white.png"
            />

            <div className="logo-and-social-media-mobile">
              <h2>Stay connected with us</h2>
              <div className="icons">
                {/* <a style={{ display: "none" }}>
                  <i className="fab fa-facebook-square fa-2x icon-footer"></i>
                </a> */}
                <a
                  style={{ color: "white" }}
                  href="https://www.instagram.com/united_automobile_insurance/?hl=en"
                  aria-label="Link to Instagram , inside of Footer "
                >
                  <i className="fab fa-instagram fa-2x icon-footer"></i>
                </a>
                <a
                  style={{ color: "white" }}
                  href="https://www.linkedin.com/company/unitedautomobileinsurance"
                  aria-label="Link to linkedin , inside of Footer "
                >
                  <i className="fab fa-linkedin fa-2x icon-footer"></i>
                </a>
              </div>
            </div>
            <div className="third-column">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.essentialaccessibility.com/uaic?utm_source=uaichomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=uaic"
              >
                <img
                  alt="english ea icon"
                  className="footer-image"
                  style={{ height: "50px" }}
                  src="/uaichome/pictures/english-ea-icon.png"
                />
              </a>

              <br></br>

              <br></br>
              <p style={{ color: "rgb(255, 255, 255)" }}>
                Managing Your Policy Just Got Easier.
              </p>
              <p style={{ color: "rgb(255, 255, 255)" }}>
                Download Our Mobile App Today!
              </p>
              <br></br>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/us/app/united-automobile-insurance/id868152074"
                  onClick={() => {
                    GA(
                      "iPhone_App_Download",
                      "User clicked on the iPhone_App_Download icon on Banner"
                    );
                  }}
                >
                  <img
                    alt="Download iPhone app"
                    className="footer-image"
                    style={{ height: "40px" }}
                    src="/uaichome/pictures/iphone.png"
                  />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.phonegap.uaic&hl=en"
                  onClick={() => {
                    GA(
                      "Android_App_Download",
                      "User clicked on the Android_App_Download icon on Footer"
                    );
                  }}
                >
                  <img
                    alt="Download android app"
                    className="footer-image"
                    style={{ height: "40px" }}
                    src="/uaichome/pictures/android.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="geo-trust-div footer-mobile">
          <div>
            <img
              src="/uaichome/pictures/GeoTrust.png"
              alt="GeoTrust Seal for United Automobile"
            ></img>
            <p>
              Copyright © {getCurrentYear()} United Automobile Insurance Company
            </p>
          </div>
        </div>
      </MobileView>
    </div>
  );
};

export default Footer;
