import React, { useState, useEffect } from "react";
import "./careersFAQ.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CareersFAQ = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={isComponentLoaded ? "CareersFAQ" : "CareersFAQ-not-rendered"}
    >
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          alt="career"
          src="/uaichome/pictures/faq.png"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Frequently Asked Questions</h2>
        <hr></hr>
        <div>
          <p>
            Do you have a question about getting a job at UAIC? Here is a list
            of frequently asked questions that we receive from applicants. If
            you don't see your question or answer here, please contact us.
          </p>
          <br></br>
          <h3>Is UAIC hiring?</h3>
          <p>
            Yes, many of our offices are hiring skilled professionals for a
            variety of positions, including customer service, data entry clerks,
            claims and underwriting. Specific job openings, shifts, and training
            classes will vary from office to office. Additionally, our corporate
            office recruits information technology professionals, attorneys and
            accounting associates. For a list of current opportunities, please
            click here
          </p>
          <br></br>
          <h3>What are the qualifications needed to work at UAIC?</h3>
          <p>
            For our sales, customer service, claims and auto damage positions,
            we are looking for candidates with strong communication and computer
            skills, and the ability to multi-task and assist our customers in a
            friendly, professional manner. We provide extensive training in each
            area; therefore a desire to learn and apply those skills is
            important to our associates' careers. No prior insurance industry
            experience is required!
          </p>
          <br></br>
          <p>
            Our management development programs, and analytical and technical
            positions have specific requirements. You'll want to review each job
            for more information.
          </p>
          <br></br>
          <h3>How old do you have to be to work for UAIC?</h3>
          <p>
            For full-time career positions, applicants must be at least 18 years
            of age to be employed by UAIC. Some offices offer work-study or
            internship programs for high school and/or college students. In
            these cases, work permits for minors may be required.
          </p>
          <br></br>
          <h3>Do I need a high school diploma?</h3>
          <p>
            For full-time careers in sales, customer service, claims and auto
            damage we require a high school diploma or equivalent. Other
            positions, including our management development programs, analyst,
            actuary and information technology positions require a college
            degree. Some clerical positions do not have a minimum education
            requirement. You'll want to check with the local Human Resources
            office if you have further questions.
          </p>
          <br></br>
          <h3>Do I need a high school diploma?</h3>
          <p>
            For full-time careers in sales, customer service, claims and auto
            damage we require a high school diploma or equivalent. Other
            positions, including our management development programs, analyst,
            actuary and information technology positions require a college
            degree. Some clerical positions do not have a minimum education
            requirement. You'll want to check with the local Human Resources
            office if you have further questions.
          </p>
          <br></br>
          <h3>Do I have to apply online?</h3>
          <p>We strongly encourage candidates to email us your resume.</p>
          <br></br>
          <h3>Can I apply in person?</h3>
          <p>
            Yes, you are welcome to apply in person and submit your application.
            If a recruiter is available to review your information, you may have
            the opportunity to interview as well. However, we cannot guarantee
            that you'll receive an interview on the same day.
          </p>
          <br></br>
          <h3>How do I set up an interview?</h3>
          <p>
            Once you've applied for employment, we will review your application.
            If your knowledge, skills, and abilities match what we are looking
            for, you will be contacted directly to set up an interview.
          </p>
          <br></br>
          <h3>Will there be pre-employment testing?</h3>
          <p>
            Our goal is to hire the best people for the right positions!
            Therefore, we conduct a series of pre-employment assessments in
            order to evaluate the skills and abilities of potential associates.
            Depending on the job for which you are applying, testing may include
            a review of basic skills, role playing and computerized assessments.
          </p>
          <br></br>
          <h3>
            I have UAIC insurance. Do I have a better chance of getting hired?
          </h3>
          <p>
            No, it is the policy of UAIC to seek, employ, and assign the
            best-qualified employees in all of our offices. We are an equal
            opportunity employer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareersFAQ;
