import React, { useEffect, useState } from "react";
import "./accessibility.css";

const Accessibility = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={
        isComponentLoaded ? "accessibility" : "accessibility-not-rendered"
      }
    >
      <div className="accessibility-box">
        <h2 className="color-blue" style={{ marginLeft: "2rem" }}>
          Accessibility Statement
        </h2>
      </div>

      <div className="statement-container">
        <center>
          <h1>Accessibility Statement</h1>
        </center>
        <ul>
          <li>
            United Automobile Insurance Accessibility (UAIC) is continually
            working to make its web content and mobile apps accessible as part
            of its online inclusion efforts. We are committed to providing a
            website that is built with accessibility in mind, so that no one,
            regardless of ability or disability is affected.
          </li>
          <br></br>
          <li>
            UAIC will make every effort to ensure any new or updated website
            content produced, will conform with the World Wide Web Consortium
            (W3C) Web Accessibility Initiative's (WAI) Web Content Accessibility
            Guidelines (WCAG) 2.1, Level A &amp; AA. To affirm our commitment,
            UAIC has invested in training for the designated individuals, such
            as website content editors and developers, in the functions of web
            accessibility tools, techniques and best practices. A cadence of
            Automated, Manual and Functional testing is currently being
            performed by a third party vendor to ensure compliance of the
            existing website, to detect WCAG 2.1 violations, which will be
            corrected to the best of our ability and tested by a team of users,
            including people with disabilities to ensure a great user experience
            for all. UAIC has brought on a third-party digital accessibility
            vendor, who is supporting the process by testing the web content and
            providing a roadmap and recommendations for remediation.
          </li>
          <br></br>
          <li>
            UAIC's goal is to ensure that everyone has access to information on
            the site and mobile apps and can fully interact with them. We are
            currently working on our website and mobile apps to identify
            potential accessibility issues and we are striving to improve the
            usability of these digital properties for people of all abilities.
          </li>
          <br></br>
          <li>
            The Assistive Technology app provided by eSSENTIAL Accessibility and
            featured on our website incorporates tools such as mouse and
            keyboard replacements, voice recognition, speech enablement,
            hands-free/touch-free navigation and more that empower the customer
            to overcome challenges and access UAIC's website and Android app
            with ease.
          </li>
          <br></br>
          <li>
            If you have difficulty accessing our website or mobile apps, or if
            you have feedback on how we can improve your online experience,
            please contact us at 305-940-5022
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Accessibility;
