import React, { useEffect, useState } from "react";
import "./contact.css";
import Florida from "./contactUsComponents/Florida";
import Georgia from "./contactUsComponents/Georgia";
import Texas from "./contactUsComponents/Texas";
import RequestHelp from "./requestHelp";
import GA from "../functions/GA";
import { useDispatch, useSelector } from "react-redux";

const Contact = () => {
  const dispatch = useDispatch();

  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  const selectedState = useSelector((state) => state.selectedContactUsTab);

  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    window.addEventListener("scroll", () =>
      setWindowScrollPosition(window.scrollY)
    );
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const href = window.location.href;
    const params = href.split("?")[1];

    // Be sure url params exist
    if (params && params !== "") {
      const result = params.split("&").reduce(function (res, item) {
        const parts = item.split("=");
        res[parts[0]] = parts[1];
        return res;
      }, {});

      console.log(result);

      if (result.state === "fl") {
        dispatch({
          type: "SET_SELECTED_CONTACT_TAB",
          payload: "FLORIDA",
        });
      } else if (result.state === "ga") {
        dispatch({
          type: "SET_SELECTED_CONTACT_TAB",
          payload: "GEORGIA",
        });
      } else if (result.state === "tx") {
        dispatch({
          type: "SET_SELECTED_CONTACT_TAB",
          payload: "TEXAS",
        });
      }
    }
  }, []);

  const contactUsNavigation = () => {
    return (
      <div
        style={{
          padding: "1rem",
          borderRight: "1px solid gray",
          height: "100%",
        }}
        className="careers-navigation"
      >
        <div style={{ position: "sticky", top: "100px" }}>
          <h3>Select your state!</h3>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "FLORIDA",
                });
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "FLORIDA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Florida
            </p>
          </div>
          <div style={{ margin: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "GEORGIA",
                });
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "GEORGIA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Georgia
            </p>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "TEXAS",
                });
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "TEXAS"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Texas
            </p>
          </div>
        </div>
      </div>
    );
  };

  const contactUsNavigationMobile = () => {
    return (
      <div>
        <div
          style={
            windowScrollPosition >= 230
              ? { position: "fixed", top: "100px" }
              : {}
          }
          className={
            isMobileNavigationOpen
              ? "navigation-mobile-open-contact-us"
              : "navigation-mobile-closed-contact-us"
          }
        >
          <div
            onClick={() => {
              setIsMobileNavigationOpen(!isMobileNavigationOpen);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Select state - {selectedState}</h3>
            {isMobileNavigationOpen ? (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-up"
              ></i>
            ) : (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-down"
              ></i>
            )}
          </div>
          <br></br>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "FLORIDA",
                });
                setIsMobileNavigationOpen(false);
                GA("Contact_Us", "User clicked on FLORIDA Contact Us ");
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "FLORIDA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Florida
            </p>
          </div>

          <div style={{ margin:"1rem"}}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "GEORGIA",
                });
                setIsMobileNavigationOpen(false);
                GA("Contact_Us", "User clicked on GEORGIA Contact Us ");
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "GEORGIA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Georgia
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "TEXAS",
                });
                setIsMobileNavigationOpen(false);
                GA("Contact_Us", "User clicked on TEXAS Contact Us ");
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "TEXAS"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Texas
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedComponent = () => {
    if (selectedState === "FLORIDA") {
      return <Florida />;
    } else if (selectedState === "GEORGIA") {
      return <Georgia />;
    } else if (selectedState === "TEXAS") {
      return <Texas />;
    }
  };

  const requestHelpNowComponent = () => {
    return (
      <div
        style={{ padding: "3rem 0rem 0rem 0rem", marginTop: "0px" }}
        className="request-help-parent"
      >
        <RequestHelp />
        <>
          <div
            style={{
              marginTop: "0px",
              backgroundColor: "#01224f",
              color: "white",
            }}
            onClick={() => {
              GA(
                "Needed_Assistance",
                "User clicked on Need Assistance in Contact Us Page "
              );
              dispatch({
                type: "SET_REQUEST_HELP",
                payload: true,
              });
            }}
            className="our-mission-div need-assistance"
            tabIndex="0"
          >
            <h2 className="request-help-clickable-header ">Need Assistance?</h2>
          </div>
        </>
      </div>
    );
  };

  return (
    <div className={isComponentLoaded ? "contact" : "contact-not-rendered"}>
      <div className="contact-us-box">
        <i
          style={{ marginLeft: "2rem" }}
          className="far fa-address-book fa-2x"
        ></i>
        <h2 style={{ marginLeft: "1rem" }}>Contact us </h2>
      </div>
      {requestHelpNowComponent()}
      <div className="contact-us-container">
        <div>
          {windowWidth >= 900
            ? contactUsNavigation()
            : contactUsNavigationMobile()}
        </div>

        {renderSelectedComponent()}
      </div>
    </div>
  );
};

export default Contact;
