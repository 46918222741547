import React, { useState, useEffect } from "react";
import "./underwriting.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Underwriting = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={
        isComponentLoaded ? "Underwriting" : "Underwriting-not-rendered"
      }
    >
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          src="/uaichome/pictures/underwriting.png"
          alt="To persons giving each other a hand shake"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Underwriting Representative</h2>
        <hr></hr>
        <div>
          <p>
            As an Underwriter Representative, you will be responsible for
            executing the sub-prime underwriting process within a regional
            office and developing & maintaining relationships with clients
            within a designated market.
          </p>
          <br></br>
          <br></br>
          <h3>In this role you will perform the following functions:</h3>
          <ul>
            <li>
              Communicate adverse decisions to clients while maintaining strong
              business rapport; negotiate decision change requests as warranted.
            </li>
            <br></br>
            <li>
              Manage workflows within assigned markets to enable excellent
              servicing levels, responsiveness, and accessibility.
            </li>
            <br></br>
            <li>
              Negotiate and resolve program and product policy exceptions
              regarding residuals, pricing, and documentation issues.
            </li>
            <br></br>
            <li>
              In cooperation with the Relationship Manager, contribute to the
              market's profitability level through volume generation and
              efficiency management.
            </li>
            <br></br>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Underwriting;
