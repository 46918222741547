import React, { useEffect, useState } from "react";
import "./claimsFaq.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import VideoCarousel from "./videosCarousel/videosCarousel";

const ClaimsFaq = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  const [isBasicOpen, setIsBasicOpen] = useState(false);
  const [isVehicleAndPropertyOpen, setIsVehilceAndProperyOpen] =
    useState(false);
  const [isRentalOpen, setIsRentalOpen] = useState(false);
  const [isMitigationOpen, setIsMitigationOpen] = useState(false);
  const [isDrivingTipsOpen, setIsDrivingTipsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    window.addEventListener("scroll", () =>
      setWindowScrollPosition(window.scrollY)
    );
    setWindowWidth(window.innerWidth);
  }, []);

  const claimsFAQNavigation = () => {
    return (
      <div
        style={{ position: "sticky", top: "100px" }}
        className="claims-faq-navigation"
      >
        <div>
          <h4>Claims FAQ</h4>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <a
              name="basic"
              href="#basic"
              onClick={(e) => {
                e.preventDefault();
                setIsBasicOpen(true);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -71);
                }, 1);
              }}
              className="claims-faq-navigation-link-item"
            >
              Basics
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              href="#vehicle"
              onClick={(e) => {
                e.preventDefault();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(true);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -71);
                }, 1);
              }}
              className="claims-faq-navigation-link-item"
            >
              Vehicle and Property Damage Appraisal
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              href="#rental"
              onClick={(e) => {
                e.preventDefault();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(true);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -71);
                }, 1);
              }}
              className="claims-faq-navigation-link-item"
            >
              Rental/Transportation Expense
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              href="#mitigation"
              onClick={(e) => {
                e.preventDefault();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(true);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -71);
                }, 1);
              }}
              className="claims-faq-navigation-link-item"
            >
              Mitigation
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              href="#driving_tips"
              onClick={(e) => {
                e.preventDefault();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(true);
                setTimeout(() => {
                  window.scrollBy(0, -71);
                }, 1);
              }}
              className="claims-faq-navigation-link-item"
            >
              Driving Safety Tips
            </a>
          </div>
        </div>
      </div>
    );
  };

  const claimsFAQNavigationMobile = () => {
    return (
      <div>
        <div
          style={
            windowScrollPosition >= 250
              ? { position: "fixed", top: "100px" }
              : {}
          }
          className={
            isMobileNavigationOpen
              ? "navigation-mobile-open-faq"
              : "navigation-mobile-closed-faq"
          }
        >
          <div
            onClick={() => {
              setIsMobileNavigationOpen(!isMobileNavigationOpen);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>FAQ</h3>
            {isMobileNavigationOpen ? (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-up"
              ></i>
            ) : (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-down"
              ></i>
            )}
          </div>
          <br></br>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <a
              // href="#basic"
              onClick={(e) => {
                document.getElementById("basic").scrollIntoView();
                // e.preventDefault();
                setIsBasicOpen(true);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -150);
                }, 1);
                setIsMobileNavigationOpen(false);
              }}
              className="claims-faq-navigation-link-item"
            >
              Basics
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              onClick={(e) => {
                document.getElementById("vehicle").scrollIntoView();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(true);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -150);
                }, 1);
                setIsMobileNavigationOpen(false);
              }}
              className="claims-faq-navigation-link-item"
            >
              Vehicle and Property Damage Appraisal
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              onClick={(e) => {
                document.getElementById("rental").scrollIntoView();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(true);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -150);
                }, 1);
                setIsMobileNavigationOpen(false);
              }}
              className="claims-faq-navigation-link-item"
            >
              Rental/Transportation Expense
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              onClick={(e) => {
                document.getElementById("mitigation").scrollIntoView();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(true);
                setIsDrivingTipsOpen(false);
                setTimeout(() => {
                  window.scrollBy(0, -150);
                }, 1);
                setIsMobileNavigationOpen(false);
              }}
              className="claims-faq-navigation-link-item"
            >
              Mitigation
            </a>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <a
              onClick={() => {
                document.getElementById("driving_tips").scrollIntoView();
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(true);
                setTimeout(() => {
                  window.scrollBy(0, -150);
                }, 1);
                setIsMobileNavigationOpen(false);
              }}
              className="claims-faq-navigation-link-item"
            >
              Driving Safety Tips
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={isComponentLoaded ? "claimsFaq" : "claimsFaq-not-rendered"}>
      <div className="about-uaic-box-mobile-only">
        <h2 style={{ marginLeft: "2rem" }}>Claims FAQ </h2>
      </div>
      <div style={{ marginLeft: "-16px" }}>
        <VideoCarousel />
      </div>
      <div className="about-uaic-box-desktop-only">
        <h2 style={{ marginLeft: "2rem" }}>Claims FAQ </h2>
      </div>

      <div className="claimsFaq-div">
        <div>
          <p>
            Need help? See short videos on how to report claims and get claim
            status from our Claims Portal
          </p>
          <br></br>
          <p>Do you have a question Regarding a claim at UAIC?</p>
          <p>
            Here is a list of frequently asked questions that we receive from
            applicants. If you don't see your question or answer here, please
            contact us.
          </p>
        </div>
        <hr style={{ marginTop: "2rem" }}></hr>
        <div className="info-claims-faq">
          <div>
            {windowWidth >= 900
              ? claimsFAQNavigation()
              : claimsFAQNavigationMobile()}
          </div>
          <div className="accordion-div">
            <Accordion
              allowZeroExpanded={true}
              onChange={() => {
                setIsBasicOpen(false);
                setIsVehilceAndProperyOpen(false);
                setIsRentalOpen(false);
                setIsMitigationOpen(false);
                setIsDrivingTipsOpen(false);
              }}
              className="accordion-div"
            >
              <div id="basic"></div>
              <AccordionItem dangerouslySetExpanded={isBasicOpen ? true : null}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What should I do if I have an accident?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul className="accordion-list">
                    <li>Remain calm </li>
                    <li>
                      If safe, move your vehicle out of traffic and turn on your
                      hazard lights
                    </li>
                    <li>Contact the police to report the loss </li>
                    <li>Get medical assistance if anyone is injured </li>
                    <li>Do not discuss or admit fault to anyone </li>
                    <li>Only discuss the loss with the police </li>
                    <li>
                      Collect any information you can from the scene. This
                      includes the insurance information, name, address, and
                      phone number of anyone involved in the loss.
                    </li>
                    <li>
                      Seek out any witnesses before they leave the scene. Obtain
                      their name, address, phone number, and any other method of
                      contact.
                    </li>
                    <li>
                      Take photos of the accident scene and all vehicles
                      involved. This would include skid marks, lights, lanes,
                      intersections, and any other aspects of the scene.
                    </li>
                    <li>
                      Be sure to secure your vehicle from any further damage
                    </li>
                    <li>
                      If your vehicle is not drivable, move to a storage free
                      facility.
                    </li>
                    <li>
                      Report the incident to United Automobile Insurance as soon
                      as possible, using our online form.
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I report a claim?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <h4>You have two options:</h4>
                  <ul className="accordion-list">
                    <li>
                      You can file a claim online 24 hours a day using this Link{" "}
                      <a href="https://myclaim.uaig.net">
                        https://myclaim.uaig.net
                      </a>
                      !
                    </li>
                    <li>
                      You can report your claim via our toll-free number during
                      regular business hours by calling{" "}
                      <a href="tel:8003442150">(800) 344-2150</a>
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What information do I need to know to report a claim?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul className="accordion-list">
                    <li>
                      United Automobile Insurance Company policy number, for
                      example, UAU 000000000
                    </li>
                    <li>
                      Insurance information and phone number of anyone else
                      involved in the accident with you
                    </li>
                    <li>Police report number and department name</li>
                    <li>The date, time and place of the accident</li>
                    <li>Knowledge of what happened in the loss</li>
                    <li>Location of your vehicle</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What is the status of my claim?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    You can locate the status of your claim using our Web
                    Portal. Provide directions here.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                id="vehicle"
                dangerouslySetExpanded={isVehicleAndPropertyOpen ? true : null}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How and when will my vehicle be inspected?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <h4>
                    After you file your claim, your vehicle's damage must be
                    appraised. We offer several hassle-free options to meet your
                    needs. Your options are:
                  </h4>
                  <ol className="accordion-list">
                    <li>
                      Drive-in estimate. Make an appointment with a network shop
                      to get your estimate.
                    </li>
                    <li>
                      Text or upload photos of your vehicle's damage. Click here
                      for a template of the sample photos.
                    </li>
                    <li>
                      In the event, your vehicle is not drivable and you are
                      unable to submit photos, we will come to you.{" "}
                    </li>
                  </ol>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if more damage is found after the repairs have started?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    We write an initial estimate based on visible damages.
                    However, new damages can be found once a repair facility
                    commences the repair. In this case, the repair facility
                    needs to call our supplement hotline and request a
                    supplement. A supplement is a subsequent estimate of the new
                    or additional damages found. If the vehicle is being
                    repaired at one of our network shops, this is a seamless
                    process. The network shops have a direct connection with us
                    and the supplement will be done promptly and without
                    additional delays.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if I don't have a repair shop?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    We have network of body shops in many areas that belong to our
                    network of repair facilities.
                    Furthermore, our network of repair facilities provide a
                    guarantee on their repairs for as long as you own your
                    vehicle.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                id="rental"
                dangerouslySetExpanded={isRentalOpen ? true : null}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if I am your insured and I need to rent a vehicle?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    The Florida Insurance Policy may provide Rental coverage if
                    you opted to purchase it. Check your Declarations page to
                    see if Rental Coverage appears there. The benefit is $20.00
                    per day up to $375.00 per policy term. If you have
                    Comprehensive coverage and your vehicle was stolen, your
                    policy includes transportation expenses. The benefit is
                    $10.00 per day, to a maximum of 30 days. This coverage
                    begins forty-eight (48) hours after the theft. Submit your
                    paid rental invoice, from a legitimate car rental company to
                    your adjuster to receive this benefit.
                  </p>
                  <p
                    style={{
                      marginLeft: "2rem",
                      marginBottom: "2rem",
                      marginTop: "2rem",
                    }}
                  >
                    The Texas Insurance Policy may provide Rental Reimbursement
                    coverage if you opted to purchase it. Check your
                    Declarations page to see if Rental Coverage appears there.
                    The benefit has three options: $20.00, $30.00, or $40.00 per
                    day up to 30 days. If you have Comprehensive coverage and
                    your vehicle was stolen, your policy includes transportation
                    expenses. The benefit is $20.00 per day, to a maximum of 30
                    days. This coverage begins forty-eight (48) hours after the
                    theft. Submit your paid rental invoice, from a legitimate
                    car rental company to your adjuster to receive this benefit.
                  </p>
                  <p style={{ marginLeft: "2rem" }}>
                    The Georgia Insurance Policy does not provide rental
                    coverage for most losses. If you have Comprehensive coverage
                    and your vehicle was stolen, your policy includes
                    transportation expenses. The benefit is $20.00 per day, to a
                    maximum of 30 days. This coverage begins forty-eight (48)
                    hours after you report the theft to us. Submit your paid
                    rental invoice, from a legitimate car rental company to your
                    adjuster to receive this benefit.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if I am not your insured and I need to rent a vehicle?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    If your adjuster has cleared coverage and has determined
                    liability in your favor, the adjuster may advise you that
                    you are eligible for rental reimbursement of $25.00 per day;
                    during the reasonable amount of time it will take to repair
                    your vehicle.
                  </p>
                  <p
                    style={{
                      marginLeft: "2rem",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <strong>
                      NOTE: The adjuster must authorize the rental in advance or
                      you may not be eligible for reimbursement.
                    </strong>
                  </p>
                  <ul className="accordion-list">
                    <li>
                      No direct billing from a rental car company will be
                      authorized. We work on a reimbursement basis only.
                    </li>
                    <li>
                      The shop of your choice should promptly order the parts
                      outlined in our estimate as soon as your vehicle is
                      dropped off. Please note it is possible for shops to
                      pre-order parts.
                    </li>
                    <li>
                      Your vehicle should be delivered to the shop on a Monday
                      or Tuesday, to avoid unnecessary weekend charges if your
                      vehicle is drivable.
                    </li>
                    <li>
                      You should proactively communicate with the shop, to
                      ensure repairs are proceeding according to schedule, and
                      that we are immediately notified of any potential
                      additional damages or charges.
                    </li>
                    <li>
                      You should promptly pickup your vehicle and return the
                      insurance replacement rental vehicle upon completion of
                      repairs.
                    </li>
                    <li>
                      Make sure to advise the rental company that you need an
                      insurance replacement vehicle.
                    </li>
                    <li>
                      Collision damage waivers, additional liability coverages,
                      gas, mileage, underage driver fees, or additional driver
                      expenses will not be reimbursed.
                    </li>
                    <li>
                      You can obtain a rental through any legitimate car rental
                      company of your choice. Please keep in mind that all
                      rental expenses will be on a reimbursement basis for
                      reasonable repair time only. Reasonable repair time is
                      calculated using the repair hours on the estimate that we
                      have provided, divided by 6 hours a day.
                    </li>
                    <li>
                      UAIC will not be responsible for any shop delays or any
                      delays resulting from a failure to mitigate damages.
                    </li>
                    <li>
                      Upon completion of your vehicle's repair, you may text or
                      mail your rental bill for consideration. However, if your
                      vehicle is later deemed a total loss, reimbursement may
                      not continue. Please include your claim number in any
                      correspondence you send us.
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                id="mitigation"
                dangerouslySetExpanded={isMitigationOpen ? true : null}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What is mitigation or legal duty to mitigate?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul className="accordion-list">
                    <li>
                      The action of reducing the severity, seriousness, or
                      painfulness of something.
                    </li>
                    <li>
                      If your vehicle is accruing storage charges, you should
                      immediately make arrangements to move it to a facility
                      that will not charge you for storage. You will likely be
                      requested to pay for the towing and storage charges
                      incurred to date. Please be sure to save your receipt as
                      we will reimburse you for reasonable charges provided that
                      we accept coverage and liability. This also applies to
                      temporary or emergency repairs to prevent further damage
                      to your vehicle or property. We will require photographs
                      of the damaged area, before its repair, along with a
                      complete itemized estimate of repairs and a payment
                      receipt.
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if I am your insured and my vehicle is at a tow yard?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    If you are our insured, regardless of your Policy State, you
                    must immediately release the vehicle to us and advise of its
                    location. We will move the vehicle to a storage free
                    facility and inspect your vehicle.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if my vehicle is at a body shop and is declared a total
                    loss?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    If you are our insured, regardless of your Policy State, you
                    must immediately release the vehicle to us and advise of its
                    location. We will move the vehicle to a storage free
                    facility and inspect your vehicle.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if the vehicle is in danger of being towed from your
                    residence?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    If you are our insured, please contact us and make
                    arrangements for the vehicle to be moved to a network shop
                    or a storage free facility.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Do I need to mitigate damages if I do not have comprehensive
                    and collision coverage?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    Yes. Although you may not have coverage to repair or pay for
                    your vehicle, you must minimize any further damages to your
                    vehicle, so we may inspect the damages.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if I am not your insured and my vehicle is at a tow
                    yard?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem", marginBottom: "2rem" }}>
                    In Florida, if you are not insured with us, you must move
                    your vehicle to a storage free facility for us to inspect
                    the vehicle.
                  </p>
                  <p style={{ marginLeft: "2rem" }}>
                    If the loss occurred in a State other than Florida, you must
                    immediately release the vehicle to us and advise of its
                    location. We will move the vehicle to a storage free
                    facility and inspect your vehicle.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What if I am not your insured and my vehicle is at a body
                    shop and declared a total loss?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem", marginBottom: "2rem" }}>
                    In Florida, if you are not insured with us, you must move
                    your vehicle to a storage free facility for us to inspect
                    the vehicle.
                  </p>
                  <p style={{ marginLeft: "2rem" }}>
                    If the loss occurred in a State other than Florida, you must
                    immediately release the vehicle to us and advise of its
                    location. We will move the vehicle to a storage free
                    facility and inspect your vehicle.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I mitigate damage to my property?{" "}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ marginLeft: "2rem" }}>
                    You should take pictures of the damage and submit 3
                    estimates for our review. If the structure has extensive
                    damages that may exceed our insured's policy limit, please
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                id="driving_tips"
                dangerouslySetExpanded={isDrivingTipsOpen ? true : null}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Can you offer driving safety tips?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul className="accordion-list">
                    <li> Don't drink and drive.</li>
                    <li>Don't text and drive.</li>
                    <li>Use hands-free devices if necessary.</li>
                    <li>Use your turn signals.</li>
                    <li>Respect yellow lights.</li>
                    <li>Obey the speed limit.</li>
                    <li>Make the appropriate adjustments for the weather.</li>
                    <li>
                      Speeding will not get you to your destination any faster.
                    </li>
                    <li>Always wear your seatbelt.</li>
                    <li>Call for a ride if you are not feeling well.</li>
                    <li>Pass safely on the left, never the right.</li>
                    <li>
                      In case of an accident or emergency pull completely off of
                      the roadway if possible.
                    </li>
                    <li>Keep your eyes on the road and hands on the wheel.</li>
                    <li>Be aware of your surroundings while driving.</li>
                    <li>
                      Green lights don't always mean go! Only enter the
                      intersection if it is safe to do so.
                    </li>
                    <li>Keep your vehicle well maintained.</li>
                    <li>Check the tire pressure regularly.</li>
                    <li>
                      Double-check any items being transported in a truck bed.
                    </li>
                    <li>
                      Keep at least a 2-second gap between you and the car ahead
                      of you.
                    </li>
                    <li>Never drive through high water.</li>
                    <li>Reduce your speed in a construction zone.</li>
                    <li>Move over and slow down for emergency vehicles.</li>
                    <li>Turn on your headlights and wipers in the rain.</li>
                    <li>Avoid driving while tired.</li>
                    <li>Look before crossing railroad tracks.</li>
                    <li>Allow plenty of time to get to your destination.</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimsFaq;
