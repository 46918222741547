import React, { useEffect, useState } from "react";
import "./requestHelp.css";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormControl,
} from "@material-ui/core";
import PuffLoader from "react-spinners/PuffLoader";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import TextField from "@material-ui/core/TextField";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import selectCorrectUrls from "../../selectCorrectUrls";
import "date-fns";

import GA from "../functions/GA";
const RequestHelp = () => {
  const dispatch = useDispatch();
  const isRequestHelpOpen = useSelector((state) => state.requestHelpReducer);
  const last4Digits = useSelector((state) => state.insuredNumberReducer);

  const [error, setError] = useState(false);
  const [step, setStep] = useState(0);
  const [stepOneValue, setStepOneValue] = useState("");
  const [dateOfAccident, setDateOfAccident] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [dateOfBirthMM, setDateOfBirthMM] = useState();
  const [dateOfBirthDD, setDateOfBirthDD] = useState();
  const [dateOfBirthYYYY, setDateOfBirthYYYY] = useState();
  const [insuredPhoneNumber, setInsuredPhoneNumber] = useState();

  const [isdateOfBirthMM, setIsdateOfBirthMMEmpty] = useState(true);
  const [isdateOfBirthDD, setIsdateOfBirthDDEmpty] = useState(true);
  const [isdateOfBirthYYYY, setIsdateOfBirthYYYYEmpty] = useState(true);

  const [howCanWeHelp, setHowCanWeHelp] = useState("");

  const [claimsSelect, setClaimsSelect] = useState();
  const [policySelect, setPolicySelect] = useState();
  const [other, setOther] = useState("");

  const [name, setName] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [stateForm, setStateForm] = useState("");
  const [policyPrefix, setPolicyPrefix] = useState("");

  const [isDateOfBirthOpen, setIsDateOfBirthOpen] = useState(false);
  const [isDateOfAccidentOpen, setIsDateOfAccidentOpen] = useState(false);

  const [requestHelpTimerSeconds, setRequestHelpTimerSeconds] = useState(5);
  const [isRequestHelpTimerRunning, setIsRequestHelpTimerRunning] =
    useState(false);

  const [isNameEmpty, setIsNameEmpty] = useState(true);
  const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(true);
  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const [isCommentEmpty, setIsCommentEmpty] = useState(true);
  const [isClaimsSelectEmpty, setIsClaimsSelectEmpty] = useState(true);
  const [isPolicySelectEmpty, setIsPolicySelectEmpty] = useState(true);
  const [disabledClaimsSubmitButton, setDisabledClaimsSubmitButton] =
    useState(true);
  const [disabledPolicySubmitButton, setDisabledPolicySubmitButton] =
    useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [userOtpCode, setUserOtpCode] = useState("");
  const [otpCode, setOtpCode] = useState("1234");

  const [otpMatches, setOtpMatches] = useState(false);
  const [showOtpMatch, setShowOtpMatch] = useState(false);

  const [seconds, setSeconds] = useState(60);
  const [isCountdownDone, setIsCountdownDone] = useState(true);
  const [loading, setLoading] = useState(false);

  const alphaCharsOnly = (e) => {
    if (
      (e.charCode >= 65 && e.charCode <= 90) ||
      (e.charCode >= 97 && e.charCode <= 122) ||
      e.charCode === 32
    ) {
    } else {
      e.preventDefault();
      return false;
    }
  };

  const numbersOnly = (string) => {
    const char = string[string.length - 1];

    if (
      char === "0" ||
      char === "1" ||
      char === "2" ||
      char === "3" ||
      char === "4" ||
      char === "5" ||
      char === "6" ||
      char === "7" ||
      char === "8" ||
      char === "9"
    ) {
      return true;
    } else return false;
  };

  function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  useEffect(() => {
    returnCorrectedDateforIE();
  }, [
    dateOfBirthMM,
    dateOfBirthDD,
    dateOfBirthYYYY,
    displayProperDOBforBrowser,
  ]);

  function dropdownMMArray(value, type) {
    var arr = [];
    var value = value;
    var startValue = 1;
    var endValue = 12;

    for (let i = startValue; i <= endValue; i++) {
      arr.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return (
      <>
        <Select
          required
          style={{ width: "90px", marginRight: "5px", textAlign: "center" }}
          className=""
          value={dateOfBirthMM}
          placeholder="mm"
          // error={policySelect === ""}
          onChange={(e) => {
            setDateOfBirthMM(e.target.value);
            if (e.target.value !== "") {
              setIsdateOfBirthMMEmpty(false);
            }
          }}
          label="mm"
        >
          {arr}
        </Select>
      </>
    );
  }
  function dropdownDDArray(value, type) {
    var type = type;
    var arr = [];
    var value = value;
    var startValue = 1;
    var endValue = 31;
    if (type == "MM") {
      startValue = 1;
      endValue = 12;
    }
    if (type == "DD") {
      startValue = 1;
      endValue = 31;
    }
    if (type == "YYYY") {
      startValue = 1940;
      endValue = new Date().getFullYear() - 16;
    }

    for (let i = startValue; i <= endValue; i++) {
      if (startValue > 10) {
        startValue = "0" + startValue;
      }
      arr.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return (
      <>
        <Select
          required
          style={{ width: "90px", marginRight: "5px", textAlign: "center" }}
          className=""
          value={dateOfBirthDD}
          placeholder="mm"
          // error={policySelect === ""}
          onChange={(e) => {
            setDateOfBirthDD(e.target.value);
            if (e.target.value !== "") {
              setIsdateOfBirthDDEmpty(false);
            }
          }}
          label="mm"
        >
          {arr}
        </Select>
      </>
    );
  }
  function dropdownYYYYArray(value, type) {
    var type = type;
    var arr = [];
    var value = value;
    var startValue = 1;
    var endValue = 31;
    if (type == "MM") {
      startValue = 1;
      endValue = 12;
    }
    if (type == "DD") {
      startValue = 1;
      endValue = 31;
    }
    if (type == "YYYY") {
      startValue = 1940;
      endValue = new Date().getFullYear() - 16;
    }

    for (let i = startValue; i <= endValue; i++) {
      if (startValue > 10) {
        startValue = "0" + startValue;
      }
      arr.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return (
      <>
        <Select
          required
          style={{ width: "90px", marginRight: "5px", textAlign: "center" }}
          className=""
          value={dateOfBirthYYYY}
          placeholder="mm"
          // error={policySelect === ""}
          onChange={(e) => {
            setDateOfBirthYYYY(e.target.value);
            if (e.target.value !== "") {
              setIsdateOfBirthYYYYEmpty(false);
            }
          }}
          label="mm"
        >
          {arr}
        </Select>
      </>
    );
  }

  function returnCorrectedDateforIE() {
    return (
      padLeadingZeros(dateOfBirthMM, 2) +
      "/" +
      padLeadingZeros(dateOfBirthDD, 2) +
      "/" +
      dateOfBirthYYYY
    );
  }

  function displayProperDOBforBrowser() {
    var properDateOfBirth = "";
    if (window.navigator.userAgent.indexOf("Trident") >= 0) {
      properDateOfBirth =
        padLeadingZeros(dateOfBirthMM, 2) +
        "/" +
        padLeadingZeros(dateOfBirthDD, 2) +
        "/" +
        dateOfBirthYYYY;
    } else {
      properDateOfBirth = dateOfBirth;
    }
    return properDateOfBirth;
  }

  function clearAllData() {
    if (step > 1) {
      setStep((step) => step - 1);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setClaimNumber("");
      setPolicyNumber("");
      setHowCanWeHelp("");
      setClaimsSelect("");
      setPolicySelect("");
      setDateOfAccident("");
      setDateOfBirth("");
      setOtpMatches(false);
      setShowOtpMatch(false);
      setUserOtpCode("");
      setOtpSent(false);
    }
  }

  useEffect(() => {
    let checkSelected = false;

    if (claimsSelect !== "") {
      checkSelected = true;
    } else if (policySelect !== "") {
      checkSelected = true;
    }

    if (name !== "" && claimsSelect !== "" && validateEmail(email) === true) {
      setDisabledClaimsSubmitButton(false);
    } else if (
      name !== "" &&
      policySelect !== "other" &&
      validateEmail(email) === true
    ) {
      setDisabledPolicySubmitButton(false);
    } else if (
      name !== "" &&
      policySelect === "other" &&
      validateEmail(email) === true &&
      otpMatches === true
    ) {
      setDisabledPolicySubmitButton(false);
    } else if (
      name == "" ||
      checkSelected === false ||
      (phoneNumber && policySelect !== "other") ||
      validateEmail(email) === false ||
      otpMatches === false
    ) {
      setDisabledClaimsSubmitButton(true);
      setDisabledPolicySubmitButton(true);
    }
  }, [
    name,
    claimsSelect,
    phoneNumber,
    email,
    policySelect,
    howCanWeHelp,
    isCommentEmpty,
    otpMatches,
    other,
  ]);

  const override = css`
    display: flex;
    margin: auto;
    color: white;
    margin-top: -7px;
  `;

  useEffect(() => {
    if (step === 0) {
      setName("");
      setEmail("");
      setPhoneNumber("");
      setClaimNumber("");
      setPolicyNumber("");
      setHowCanWeHelp("");
      setClaimsSelect("");
      setPolicySelect("");
      setDateOfAccident("");
      setDateOfBirth("");
      setOtpMatches(false);
      setShowOtpMatch(false);
      setUserOtpCode("");
      setOtpSent(false);
    }
  }, [step]);

  const handleRadioChange = (e) => {
    setStepOneValue(e.target.value);
    if (e.target.value !== "") {
      setError(false);
    }
  };
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    if (isRequestHelpTimerRunning === true) {
      let countDown = requestHelpTimerSeconds;
      let interval = setInterval(() => {
        countDown--;
        setRequestHelpTimerSeconds(
          (requestHelpTimerSeconds) => requestHelpTimerSeconds - 1
        );

        if (countDown <= 0) {
          clearInterval(interval);
          setIsRequestHelpTimerRunning(false);
        }
      }, 1000);
    }
  }, [isRequestHelpTimerRunning]);

  const handleSubmit = (e, currentStep) => {
    e.preventDefault();

    const handleGoodResponse = (response) => {
      if (response.data.text === "OK" && response.data.isDuplicate !== "Yes") {
        dispatch({
          type: "SET_MESSAGE",
          payload: "Verified",
        });
        dispatch({
          type: "SET_SECCESS_MESSAGE",
          payload: true,
        });
        setTimeout(() => {
          setStep(2);
        }, 2000);
      } else if (response.data.isDuplicate === "Yes") {
        dispatch({
          type: "SET_MESSAGE",
          payload: "Request for assistance was already submitted.",
        });
        dispatch({
          type: "SET_ERROR_MESSAGE",
          payload: true,
        });
      } else {
        dispatch({
          type: "SET_MESSAGE",
          payload: "Invalid Data.",
        });
        dispatch({
          type: "SET_ERROR_MESSAGE",
          payload: true,
        });
      }
    };

    if (currentStep === 0) {
      if (stepOneValue === "") {
        setError(true);
      } else {
        setStep(1);
      }
    } else if (currentStep === 1) {
      if (stepOneValue === "policy") {
        GA(
          "Needed_Assistance_Policy",
          `${policyNumber} tried to request assistance `
        );
        axios
          .post(
            `${
              selectCorrectUrls().insuredPortal
            }/insurednew/insured/review/checkpolicy?policyNo=${policyNumber}&dob=${displayProperDOBforBrowser()}`
          )

          .then((response) => {
            setStateForm(response.data.state);
            setPolicyPrefix(response.data.prefix);
            setInsuredPhoneNumber(response.data.InsuredPhone);
            dispatch({
              type: "SET_AGENT_INFO",
              payload: {
                AgentName: response.data.agencyName,
                AgentPhone: response.data.agencyPhone,
              },
            });

            if (
              response.data.text === "OK" &&
              response.data.isDuplicate !== "Yes"
            ) {
              dispatch({
                type: "SET_MESSAGE",
                payload: "Verified",
              });
              dispatch({
                type: "SET_INSURED_NUMBER",
                payload: response.data.InsuredPhone.substring(6, 10),
              });

              dispatch({
                type: "SET_SECCESS_MESSAGE",
                payload: true,
              });
              setTimeout(() => {
                setStep(2);
              }, 2000);
            } else if (response.data.isDuplicate === "Yes") {
              GA(
                "Needed_Assistance_Duplicate",
                `${policyNumber} tried to request assistance twice `
              );
              dispatch({
                type: "SET_MESSAGE",
                payload: "Request for assistance was already submitted.",
              });
              dispatch({
                type: "SET_ERROR_MESSAGE",
                payload: true,
              });
            } else {
              dispatch({
                type: "SET_MESSAGE",
                payload: "Invalid Data. Please try again",
              });
              dispatch({
                type: "SET_ERROR_MESSAGE",
                payload: true,
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (stepOneValue === "claims") {
        GA(
          "Needed_Assistance_Claims",
          `${claimNumber} tried to request assistance `
        );
        window.location =
          "https://account-management-claims-uaic-gwcpprod-prod.api.delta4-andromeda.guidewire.net/account-management-claims/tpp-claims";

        // axios
        //   .post(
        //     `${
        //       selectCorrectUrls().insuredPortal
        //     }/insurednew/insured/review/checkclaim?claimno=${claimNumber}&doa=${dateOfAccident}`
        //   )
        //   .then((response) => {
        //     setStateForm(response.data.state);

        //     if (
        //       response.data.text === "OK" &&
        //       response.data.isDuplicate !== "Yes"
        //     ) {
        //       dispatch({
        //         type: "SET_MESSAGE",
        //         payload: "Verified",
        //       });
        //       dispatch({
        //         type: "SET_SECCESS_MESSAGE",
        //         payload: true,
        //       });

        //       setTimeout(() => {
        //         setStep(2);
        //       }, 2000);
        //     } else if (response.data.isDuplicate === "Yes") {
        //       dispatch({
        //         type: "SET_MESSAGE",
        //         payload: "Request for assistance was already submitted.",
        //       });
        //       dispatch({
        //         type: "SET_ERROR_MESSAGE",
        //         payload: true,
        //       });
        //     } else {
        //       dispatch({
        //         type: "SET_MESSAGE",
        //         payload: "Invalid Data.",
        //       });
        //       dispatch({
        //         type: "SET_ERROR_MESSAGE",
        //         payload: true,
        //       });
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      }
    } else if (step === 2) {
      setLoading(true);

      if (policySelect === "lien_holder") {
        dispatch({
          type: "SET_REQUEST_HELP_REASON",
          payload: policySelect,
        });

        return (window.location.hash = `#/uaichome/requestedHelp?PorC=${
          stepOneValue === "claims" ? claimNumber : policyNumber
        }`);
      }

      axios
        .get(
          `${
            selectCorrectUrls().insuredPortal
          }/insurednew/insured/review/cconfirm?policyOrClaim=${
            stepOneValue === "claims"
              ? claimNumber
              : policyPrefix + policyNumber
          }&returnedDate=${
            stepOneValue === "claims" ? dateOfAccident : dateOfBirth
          }&csrName=${name}&reasonFeedback=${
            stepOneValue === "claims" ? claimsSelect : policySelect
          }&feedbackfor=${stepOneValue === "claims" ? "C" : "U"}&phone=${
            !phoneNumber ? "000" : phoneNumber
          }&email=${email}&relation=insured&statecode=${stateForm}`
        )
        .then((response) => {
          // console.log(response);
          // setRequestHelpTimerSeconds(5);
          // setIsRequestHelpTimerRunning(false);
          setLoading(false);

          // Here
          console.log(response);

          if (response.data.text === "OK") {
            dispatch({
              type: "SET_REQUEST_HELP_REASON",
              payload: policySelect,
            });
            dispatch({
              type: "SET_REQUEST_HELP",
              payload: false,
            });
            dispatch({
              type: "SET_SECCESS_MESSAGE",
              payload: true,
            });
            dispatch({
              type: "SET_MESSAGE",
              payload: "Message sent successfully",
            });

            if (
              policySelect === "make_a_payment" ||
              policySelect === "renew_policy"
            ) {
              window.location.replace(
                `${selectCorrectUrls().insuredPortal}/insurednew`
              );
            } else if (policySelect === "lien_holder") {
              window.location.hash = `#/uaichome/requestedHelp?PorC=${
                stepOneValue === "claims" ? claimNumber : policyNumber
              }`;
            } else {
              window.location.hash = `#/uaichome/requestedHelp?PorC=${
                stepOneValue === "claims" ? claimNumber : policyNumber
              }`;
            }

            setStep(0);
          } else {
            dispatch({
              type: "SET_MESSAGE",
              payload: response.data.text + ". Please try again",
            });
            dispatch({
              type: "SET_ERROR_MESSAGE",
              payload: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      setRequestHelpTimerSeconds(5);
    }
  };

  const sendOtp = () => {
    //12/09/1986
    //983100

    //900738
    //06/29/1955
    // console.log("BEFORE AXIOS");
    axios
      .post(
        `  ${
          selectCorrectUrls().insuredPortal
        }/insurednew/insured/pin/sendpin?conr=01&prefix=${policyPrefix}&policyno=${policyNumber}&phone=${phoneNumber}`
      )
      .then((response) => {
        // console.log("hello");
        // console.log(response);
        if (response.data === "\r\nOK") {
          // console.log("INSIDE OK");
          dispatch({
            type: "SET_MESSAGE",
            payload: "Verification Message Sent",
          });
          dispatch({
            type: "SET_SECCESS_MESSAGE",
            payload: true,
          });
          setOtpSent(true);
          setSeconds(60);
          setIsCountdownDone(false);
        } else if (response.data === "\r\nNOK") {
          dispatch({
            type: "SET_MESSAGE",
            payload: "Phone number does not match with phone on record",
          });
          dispatch({
            type: "SET_ERROR_MESSAGE",
            payload: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const verifyOtp = () => {
    axios
      .post(
        `  ${
          selectCorrectUrls().insuredPortal
        }/insurednew/insured/pin/validate?conr=01&prefix=${policyPrefix}&policyno=${policyNumber}&phone=${phoneNumber}&pin=${userOtpCode}`
      )
      .then((response) => {
        if (response.data === "\r\nOK") {
          setOtpMatches(true);
          setShowOtpMatch(true);
        } else if (response.data === "\r\nNOK") {
          setOtpMatches(false);
          setShowOtpMatch(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isCountdownDone === false) {
      const interval = setInterval(() => {
        if (seconds <= 0) {
          setIsCountdownDone(true);
        }

        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isCountdownDone, seconds]);

  const claimsRelated = () => {
    return (
      <div className="claims-related-container">
        <div className="request-help-component-inner-header">
          <h2>Claims related:</h2>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              handleSubmit(e, 2);
            }}
            className="request-help-content"
          >
            <FormControl
              component="fieldset"
              error={error}
              variant="outlined"
              className="claims-related-inner"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Name: </h3>
                <TextField
                  onChange={(e) => {
                    setName(e.target.value);
                    if (e.target.value.length >= 3) {
                      setIsNameEmpty(false);
                    }
                  }}
                  value={name}
                  type="text"
                  required={true}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  error={name.length >= 1 && name.length <= 3}
                  onKeyPress={(e) => {
                    alphaCharsOnly(e);
                  }}

                  // helperText={name === "" ? "Empty field!" : " "}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Relation:</h3>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Relation
                  </InputLabel>
                  <Select
                    required
                    style={{ width: "220px" }}
                    labelId="demo-simple-select-outlined-label"
                    className="reason-dropdown"
                    value={claimsSelect}
                    onChange={(e) => {
                      setClaimsSelect(e.target.value);
                      if (e.target.value !== "") {
                        setIsClaimsSelectEmpty(false);
                      }
                    }}
                    label="Relation"
                  >
                    <MenuItem value={"policy_holder"}>Policy Holder</MenuItem>
                    <MenuItem value={"service_provider"}>
                      Service Provider
                    </MenuItem>
                    <MenuItem value={"body_shop"}>Body Shop</MenuItem>
                    <MenuItem value={"rental"}>Rental Company</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Phone Number: </h3>
                <TextField
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    if (e.target.value.length == 10) {
                      setIsPhoneNumberEmpty(false);
                    } else {
                      setIsPhoneNumberEmpty(true);
                    }
                  }}
                  value={phoneNumber}
                  type="number"
                  required={true}
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  error={phoneNumber.length > 1 && phoneNumber.length !== 10}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Email: </h3>
                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (validateEmail(e.target.value) === true) {
                      setIsEmailEmpty(false);
                    } else {
                      setIsEmailEmpty(true);
                    }
                  }}
                  value={email}
                  type="email"
                  required={true}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  error={email.length > 1 && validateEmail(email) === false}
                />
              </div>
              <br></br>

              <Button
                type="submit"
                variant="outlined"
                id="claims-request-help-submit-"
                className="request-help-submit-button"
                color="primary"
                disabled={
                  loading || disabledClaimsSubmitButton ? "disabled" : ""
                }
                onClick={(e) => {
                  // setIsRequestHelpTimerRunning(true);
                  handleSubmit(e, 2);
                }}
              >
                {loading || isRequestHelpTimerRunning ? (
                  <>
                    <PuffLoader color={"white"} css={override} size={30} />
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </FormControl>
          </form>
        </div>
      </div>
    );
  };

  const policyRelated = () => {
    return (
      <div className="policy-related-container">
        <div className="request-help-component-inner-header">
          <h2>Policy related:</h2>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              handleSubmit(e, 2);
            }}
            className="request-help-content"
          >
            <FormControl
              component="fieldset"
              error={error}
              variant="outlined"
              className="policy-related-inner"
              // className={classes.formControl}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Name: </h3>
                <TextField
                  onChange={(e) => {
                    setName(e.target.value);
                    if (e.target.value.length >= 3) {
                      setIsNameEmpty(false);
                    }
                  }}
                  value={name}
                  type="text"
                  required={true}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  error={name.length >= 1 && name.length <= 3}
                  pattern="[a-zA-Z]*"
                  onKeyPress={(e) => {
                    alphaCharsOnly(e);
                  }}
                  className="input-set-width"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Reason:</h3>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Reason
                  </InputLabel>
                  <Select
                    required
                    style={{ width: "220px" }}
                    className="reason-dropdown input-set-width"
                    value={policySelect}
                    // error={policySelect === ""}
                    onChange={(e) => {
                      setPolicySelect(e.target.value);
                      if (e.target.value !== "") {
                        setIsPolicySelectEmpty(false);
                      }
                    }}
                    label="Reason"
                  >
                    <MenuItem value={"change_policy"}>
                      Make a change to my policy
                    </MenuItem>
                    <MenuItem value={"lien_holder"}>
                      Need a verification of insurance for lien holder
                    </MenuItem>
                    <MenuItem value={"verification_state"}>
                      Need verification of insurance for the state
                    </MenuItem>
                    <MenuItem value={"pending_cancel"}>
                      My policy is pending Cancellation
                    </MenuItem>
                    <MenuItem value={"cancel_policy"}>
                      I would like to cancel my policy
                    </MenuItem>
                    <MenuItem value={"pending_nonrenewal"}>
                      My policy has a pending non-renewal
                    </MenuItem>
                    <MenuItem value={"make_a_payment"}>Make a payment</MenuItem>
                    <MenuItem value={"renew_policy"}>Renew Policy</MenuItem>

                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {policySelect === "buy_policy" ? (
                <>
                  <Link to="/">
                    <div style={{ textAlign: "center", margin: "10px 0px;" }}>
                      Click Here to Buy Policy
                    </div>
                  </Link>
                  <br></br>
                </>
              ) : (
                ""
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginRight: "2rem" }}>Email: </h3>
                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (validateEmail(e.target.value) === true) {
                      setIsEmailEmpty(false);
                    } else {
                      setIsEmailEmpty(true);
                    }
                  }}
                  value={email}
                  type="email"
                  required={true}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  error={email.length >= 1 && validateEmail(email) === false}
                  className="input-set-width"
                />
              </div>
              {policySelect === "other" ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px 0px",
                      color: "red",
                    }}
                  >
                    Please verify phone number ending in XXX-XXX-
                    {last4Digits}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3
                      className="verification-options-header"
                      style={{ marginRight: "2rem" }}
                    >
                      Phone Number:{" "}
                    </h3>
                    <div style={{ display: "flex", alignItems: "center " }}>
                      <TextField
                        disabled={otpMatches || !isCountdownDone}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          if (e.target.value.length == 10) {
                            setIsPhoneNumberEmpty(false);
                          } else {
                            setIsPhoneNumberEmpty(true);
                          }
                        }}
                        value={phoneNumber}
                        type="number"
                        required={policySelect === "other" ? true : false}
                        id="outlined-basic"
                        label="Phone number"
                        variant="outlined"
                        error={
                          phoneNumber.length >= 1 && phoneNumber.length !== 10
                        }
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        style={{ width: "190px" }}
                      />
                      <Button
                        disabled={
                          otpMatches ||
                          phoneNumber.length < 10 ||
                          !isCountdownDone
                        }
                        style={{
                          marginLeft: 5,
                          marginBottom: 10,
                          width: "85px",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          sendOtp();
                        }}
                      >
                        {!isCountdownDone
                          ? seconds
                          : otpSent
                          ? "Resend"
                          : "Verify"}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {otpSent && policySelect === "other" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3
                    className="verification-options-header"
                    style={{ marginRight: "2rem" }}
                  >
                    Verification Code:{" "}
                  </h3>
                  <div style={{ display: "flex", alignItems: "center " }}>
                    <TextField
                      onChange={(e) => {
                        setUserOtpCode(e.target.value);
                      }}
                      value={userOtpCode}
                      type="number"
                      required={true}
                      id="outlined-basic"
                      label="Code"
                      variant="outlined"
                      error={
                        userOtpCode.length >= 1 && userOtpCode.length !== 4
                      }
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                      disabled={otpMatches}
                      style={{ width: "190px" }}
                    />
                    <Button
                      disabled={otpMatches || userOtpCode.length < 4}
                      style={{ marginLeft: 5, marginBottom: 10 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        verifyOtp();
                      }}
                    >
                      Verify
                    </Button>
                  </div>
                </div>
              )}
              {showOtpMatch &&
                (otpMatches ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h5 style={{ color: "green", fontSize: 16 }}>
                      Phone Number has been verified
                    </h5>
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h5 style={{ color: "red", fontSize: 16 }}>
                      Incorrect verification code
                    </h5>
                  </div>
                ))}

              <br></br>
              <Button
                type="submit"
                className="request-help-submit-button"
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  setIsRequestHelpTimerRunning(true);
                  handleSubmit(e, 2);
                }}
                disabled={loading || disabledPolicySubmitButton ? true : false}
              >
                {loading || isRequestHelpTimerRunning ? (
                  <>
                    <PuffLoader color={"white"} css={override} size={30} />
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </FormControl>
          </form>
        </div>
      </div>
    );
  };

  const renderSteps = () => {
    if (step === 0) {
      return (
        <div>
          <div className="request-help-header">
            <h2>How Can We Help?</h2>
          </div>
          <div className="request-help-component-inner-header">
            <h2> Tell us what is it related to</h2>
          </div>
          <div>
            <form
              onSubmit={(e) => {
                handleSubmit(e, 0);
              }}
              className="request-help-content"
            >
              <FormControl
                component="fieldset"
                error={error}
                // className={classes.formControl}
              >
                <FormLabel component="legend"></FormLabel>
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={stepOneValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="policy"
                    control={<Radio />}
                    label="Policy"
                  />
                  <FormControlLabel
                    value="claims"
                    control={<Radio />}
                    label="Claims"
                  />
                </RadioGroup>

                <Button
                  type="submit"
                  className="request-help-continue-button"
                  variant="outlined"
                >
                  Continue
                </Button>
              </FormControl>
            </form>
          </div>
        </div>
      );
    } else if (step === 1) {
      if (stepOneValue === "policy") {
        return (
          <div>
            <div className="request-help-component-inner-header">
              <h2>Please verify your information below:</h2>
            </div>
            <div>
              <form
                onSubmit={(e) => {
                  handleSubmit(e, 1);
                }}
                className="request-help-content"
              >
                <FormControl>
                  <div
                    className="verification-options"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h3
                      className="verification-options-policy"
                      style={{ marginRight: "2rem" }}
                    >
                      Policy Number:{" "}
                    </h3>
                    <TextField
                      onChange={(e) => {
                        setPolicyNumber(e.target.value);
                        if (policyNumber.length >= 9) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onInput={(e) => {
                        if (!numbersOnly(e.target.value)) {
                          // console.log("its not a number");
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          );
                        }
                      }}
                      inputProps={{
                        maxLength: 9,
                      }}
                      value={policyNumber}
                      type="text"
                      required={true}
                      id="outlined-basic"
                      placeholder="Policy #"
                      variant="outlined"
                      className="input-set-width"
                    />
                  </div>
                  <div
                    className="verification-options"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      marginBottom: "2rem",
                    }}
                  >
                    <h3 style={{ marginRight: "47px" }}>Date of Birth: </h3>

                    {window.navigator.userAgent.indexOf("Trident") >= 0 ? (
                      <>
                        {dropdownMMArray(12, "MM")}
                        {dropdownDDArray(31, "DD")}
                        {dropdownYYYYArray(30, "YYYY")}
                      </>
                    ) : (
                      <input
                        className="date-picker input-set-width"
                        type="date"
                        id="start"
                        name="trip-start"
                        variant="outlined"
                        onChange={(e) => {
                          setDateOfBirth(e.target.value);
                          setIsDateOfBirthOpen(false);
                        }}
                        value={dateOfBirth}
                        min="1950-01-01"
                        max={new Date().toISOString().split("T")[0]}
                        required
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  </div>

                  <Button
                    type="submit"
                    className="request-help-continue-button"
                    variant="outlined"
                  >
                    Continue
                  </Button>
                </FormControl>
              </form>
            </div>
          </div>
        );
      }
      // return (
      else
        window.location = `https://account-management-claims-uaic-gwcpprod-prod.api.delta4-andromeda.guidewire.net/account-management-claims/tpp-claims`;

      // <div>
      //   <div className="request-help-component-inner-header">
      //     <h2>Please verify your information below:</h2>
      //   </div>
      //   <div>
      //     <form
      //       onSubmit={(e) => {
      //         handleSubmit(e, 1);
      //       }}
      //       className="request-help-content"
      //     >
      //       <FormControl>
      //         <div
      //           className="verification-options"
      //           style={{ display: "flex", alignItems: "center" }}
      //         >
      //           <h3 style={{ marginRight: "2rem" }}>Claim Number: </h3>
      //           <TextField
      //             onChange={(e) => {
      //               setClaimNumber(e.target.value);
      //             }}
      //             value={claimNumber}
      //             type="number"
      //             required={true}
      //             id="outlined-basic"
      //             placeholder="Claim Number"
      //             variant="outlined"
      //           />
      //         </div>
      //         <div
      //           className="verification-options"
      //           style={{
      //             display: "flex",
      //             alignItems: "center",

      //             marginBottom: "2rem",
      //           }}
      //         >
      //           <h3 style={{ marginRight: "12px" }}>Date of Accident: </h3>
      //           <input
      //             className="date-picker"
      //             type="date"
      //             id="start"
      //             name="trip-start"
      //             onChange={(e) => {
      //               setDateOfAccident(e.target.value);
      //               setIsDateOfAccidentOpen(false);
      //             }}
      //             value={dateOfAccident}
      //             min="2000-01-01"
      //             max={new Date().toISOString().split("T")[0]}
      //             required
      //           />
      //         </div>

      //         <Button
      //           type="submit"
      //           className="request-help-continue-button"
      //           variant="outlined"
      //         >
      //           Continue
      //         </Button>
      //       </FormControl>
      //     </form>
      //   </div>
      // </div>
      // );
    } else if (step === 2) {
      if (stepOneValue === "claims") {
        return claimsRelated();
      } else return policyRelated();
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          dispatch({
            type: "SET_REQUEST_HELP",
            payload: false,
          });
          setStep(0);
        }}
        className={
          isRequestHelpOpen
            ? "continue-previous-quote-dark-background-on"
            : "continue-previous-quote-dark-background-off"
        }
      ></div>
      <div
        style={{
          height: "fitContent",
        }}
        className={
          isRequestHelpOpen ? "request-help-open" : "request-help-hidden"
        }
      >
        <div className="request-help-component-header">
          <h2>
            {step === 0 ? (
              ""
            ) : (
              <span
                onClick={() => {
                  setStep(0);
                }}
                className="request-help-back-button"
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ color: "#01224f" }}
                  aria-hidden="true"
                >
                  &nbsp;Back
                </i>
              </span>
            )}
          </h2>
          <div
            onClick={() => {
              dispatch({
                type: "SET_REQUEST_HELP",
                payload: false,
              });
              setStep(0);
            }}
            className="close-login-menu-icon"
          >
            <i
              className="fa fa-times-circle"
              style={{ fontSize: "2em", color: "#01224f" }}
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <div>{renderSteps()}</div>
      </div>
    </div>
  );
};

export default RequestHelp;
