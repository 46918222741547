import React, { useState, useEffect } from "react";
import "./careersFirstPage.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GA from "../../../functions/GA";

const CareersFirstPage = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={
        isComponentLoaded ? "careersFirstPage" : "careersFirstPage-not-rendered"
      }
    >
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          alt="career"
          src="/uaichome/pictures/careers.png"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <a
          onClick={() => {
            GA(
              "Employment_Opportunities",
              "User clicked on the Employment_Opportunities on Careers Page"
            );
          }}
          target="_blank"
          rel="noreferrer"
          href="https://uaig.applytojob.com/apply"
        >
          <h2 className="blue-color">Employment Opportunities</h2>
        </a>
        <hr></hr>
        <p>
          UAIC is always looking for smart, ethical, passionate, creative and
          energetic people to join our team. If we don't have any positions
          posted at the moment, please check back with us.
        </p>
        <div className="view-jobs">
          {/* <a
            href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=85b114ae-da67-42eb-bd2b-36c970ea9207&amp;ccId=19000101_000001&amp;type=MP&amp;lang=en_US"
            target="_blank"
            rel="noreferrer"
          >
            Please Click Here to View All Job Listings
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default CareersFirstPage;
