import React, { useState, useEffect } from "react";
import "./corporate.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Corporate = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div className={isComponentLoaded ? "Corporate" : "Corporate-not-rendered"}>
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          src="/uaichome/pictures/corporate.png"
          effect="blur"
          alt="Group of agents wearing nice suits and smiling awkwardly at the camera"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Corporate & Executive Professionals</h2>
        <hr></hr>
        <div>
          <p>
            Human Resources, Actuary, Marketing and lots more ... wherever your
            professional interests lie, UAIC has great careers for the right
            people.
          </p>
          <br></br>
          <p>
            UAIC's corporate headquarters is located in Miami Gardens, Florida.
            Here, you will find our Finance and Accounting, Marketing,
            Underwriting, and Legal departments.
          </p>
          <br></br>
          <p>
            Associates working at our headquarters support our business
            operations, work closely with our regional offices, and make real
            contributions to the company's bottom line. Our Florida area office
            offers rewarding career opportunities in an exciting metropolitan
            setting.
          </p>
          <br></br>
          <p>
            For a list of current opportunities, please{" "}
            <a href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=85b114ae-da67-42eb-bd2b-36c970ea9207&ccId=19000101_000001&type=MP&lang=en_US">
              click here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Corporate;
