import React, { useEffect, useState } from "react";
import "./becomeAgent.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BecomeAgent = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div>
      <div style={{ marginBottom: "2rem" }} className="about-uaic-box">
        <i style={{ marginLeft: "2rem" }} className="fas fa-user-tie fa-2x"></i>
        <h2 style={{ marginLeft: "1rem" }}>Become an Agent!</h2>
      </div>
      <div
        className={isComponentLoaded ? "baa-banner" : "baa-banner-not-rendered"}
      >
        <div className="blue-color">
          <div>
            <p className="baa">
              Become an Agent
              <p style={{ fontSize: "20px" }}>Get Started Today!</p>
            </p>
            <LazyLoadImage
              className="baa-img"
              alt="Group of people waving at the camera"
              src="/uaichome/pictures/baa.jpg"
              effect="blur"
            />
          </div>
          <div className="txt-input-div">
            <select
              onChange={(e) => {
                openInNewTab(e.target.value);
              }}
              className="txt-input-select"
            >
              <option value="" disabled selected hidden>
                - Select a State -
              </option>

              <option value="https://baa.uaig.net/fl/index.php">Florida</option>
              {/* <option value="https://baa.uaig.net/ga/index.php">Georgia</option> */}
              <option value="https://baa.uaig.net/tx/index.php">Texas</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeAgent;
