import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  const privacyPolicyNavigation = () => {
    return (
      <div className="careers-navigation">
        <div style={{ position: "sticky", top: 100 }}>
          <div>
            <h4>What UAIC Offers</h4>
            <hr></hr>
            <div style={{ marginLeft: "1rem" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("Collected");
                }}
                className="careers-navigation-link-item"
              >
                What We Do with Collected Information
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("Cookies");
                }}
                className="careers-navigation-link-item"
              >
                Use of Cookies
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("web-beacons");
                }}
                className="careers-navigation-link-item"
              >
                Use of Web Beacons{" "}
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("personal-info");
                }}
                className="careers-navigation-link-item"
              >
                Controlling Your Personal Information{" "}
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("security");
                }}
                className="careers-navigation-link-item"
              >
                Security of Your Personal Information
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("structure");
                }}
                className="careers-navigation-link-item"
              >
                Changes in Corporate Structure
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("visitors");
                }}
                className="careers-navigation-link-item"
              >
                Visitors Outside the United States
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("changes");
                }}
                className="careers-navigation-link-item"
              >
                Changes to Our Website Privacy Policy
              </p>
            </div>
            <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
              <p
                onClick={() => {
                  scrollIntoComponent("contact");
                }}
                className="careers-navigation-link-item"
              >
                Contact Information
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const scrollIntoComponent = (componentId) => {
    const yOffset = -100;
    const element = document.getElementById(componentId);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className={isComponentLoaded ? "privacy" : "privacy-not-rendered"}>
      <div className="privacy-box">
        <h2 className="color-blue" style={{ marginLeft: "2rem" }}>
          Privacy Policy{" "}
        </h2>
      </div>
      <div className="site-map-div">
        <div>{privacyPolicyNavigation()}</div>
        <div className="component-to-display-div">
          <div>
            <h3 id="privacy-policy">
              United Automobile Insurance Company Website Privacy Policy
            </h3>
            <hr></hr>
            <p>
              UAIC is strongly committed to protecting your privacy and
              providing a safe online experience for all our users. This Privacy
              Policy applies to information collected online through UAIC's Web
              site, which includes all of the UAIC Agent-Sites and governs
              online data collection and usage by UAIC.Please read the complete
              UAIC Website Privacy Policy below and{" "}
              <Link to="/uaichome/contact">contact us</Link> if you have any
              questions.
            </p>

            <br></br>
            <br></br>

            <h3 id="tracking">
              Collection and Tracking of Personal Information
            </h3>
            <hr></hr>
            <p>
              So that we can provide you with the most efficient and enhanced
              service, we request information about you. We collect personally
              identifiable information such as names, email addresses and
              demographic information such as age, gender and zip code. We also
              may collect your IP address, browser type, domain name, access
              times and referring Web site address. This information is
              collected both during registration on a site or in response to
              specific requests, for example when signing up for a newsletter,
              entering a sweepstakes or contest or answering a survey.
              Information collected at one UAIC Web site will be available
              within the UAIC family of Web sites.
            </p>

            <br></br>
            <br></br>

            <h3 id="Collected">What We Do With Collected Information</h3>
            <hr></hr>
            <p>
              We use personal information so that we may display custom,
              personalized content to our visitors and send newsletters, emails,
              features, promotional material, surveys and other updates to our
              users. A user may, at any time, opt out of receiving such
              information. We also use collected information to evaluate and
              administer our service, respond to any problems that may arise and
              to gauge user trends to determine what part of our services are
              most popular with users.
              <br></br>
              <br></br>
              We may extend offers to users on behalf of our partners about a
              particular product or service that may be of interest to you, but
              we will never give out your name or personal information at any
              time without your permission. As result, although UAIC may receive
              a fee from our partners for this service, UAIC sends all the
              offers so that your personal information is never shared with any
              outside third party.
              <br></br>
              <br></br>
              We may, at times, produce reports for advertisers containing
              aggregate information about our users such as demographic, age and
              online usage information. This allows us to work more closely with
              advertisers to provide content and offers that are most relevant
              to our users. We will never include any personal information of
              our users in these reports.
              <br></br>
              <br></br>
              We allow users of our Web site to send articles or links by email
              to other parties who may be interested in them. We do not retain
              any email addresses (unless you are a member who already has
              voluntarily provided your email address) of either the sender or
              recipient of such articles or links after they are sent.
              <br></br>
              <br></br>
              We reserve the right to use or disclose any information without
              notice or consent as needed to satisfy any law, regulation or
              legal request; to conduct investigations of consumer complaints or
              possible breaches of law; to protect the integrity of our site and
              our property; to protect the safety of our visitors or others; to
              fulfill your requests; or to cooperate in any legal investigation.
              <br></br>
              <br></br>
              You should be aware that if you voluntarily disclose information
              online (whether through UAIC's services or any other service
              available online), personal or otherwise, that information can be
              collected and used by others. For example, if you use chat,
              forums, bulletin boards, your e-mail or if you post personal
              information online, you may receive unsolicited messages from
              others. UAIC has no control over this use of your personal
              information.
            </p>

            <br></br>
            <br></br>

            <h3 id="Cookies">Use of Cookies</h3>
            <hr></hr>
            <p>
              Your browser offers so-called "cookies" which, if you allow their
              use, store small amounts of data on your computer when you visit a
              Web site. Cookies do not contain any personally identifiable
              information about you and therefore cannot be used to identify you
              personally. Cookies simply assist us in tracking which of our
              features you like best. Cookies also enable us to customize our
              content according to your preferences. You have the ability to
              accept or decline cookies by modifying your browser and our Web
              sites are viewable even if you disable the cookie function on your
              browser. However, if you disable cookies, some advanced and
              customized features of our site, as well as other Web sites, will
              not be available to you.
            </p>

            <br></br>
            <br></br>

            <h3 id="web-beacons">Use of Web Beacons</h3>
            <hr></hr>
            <p>
              Some of our Web pages may contain electronic images known as Web
              beacons, which are also called single-pixel gif's. These images
              allow us to count users who have visited those pages and to
              deliver co-branded services. We also may include Web beacons in
              promotional e-mail messages or newsletters in order to count how
              many messages have been opened and acted upon. Web beacons are not
              used to access your personally identifiable information but they
              are a technique we use to compile aggregated statistics about our
              Web site usage. Web beacons collect only a limited set of
              information including a cookie number, time and date of a page
              view, originating IP address, and a description of the page on
              which the Web beacon resides. Some of our Web pages may also
              contain Web beacons placed there by our advertising partners to
              help us determine the effectiveness of our advertising campaigns.
            </p>
            <br></br>
            <br></br>

            <h3 id="personal-info">Controlling Your Personal Information</h3>
            <hr></hr>
            <p>
              Users can unsubscribe from any newsletters or opt out of various
              promotional emailings at any time by clicking on "unsubscribe"
              links in each of our newsletters or emails.
            </p>
            <br></br>
            <p>
            No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. Information sharing to subcontractors in support services, such as customer service, is permitted. All other use case categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
            </p>

            <br></br>
            <br></br>

            <h3 id="security">Security of Your Personal Information</h3>
            <hr></hr>
            <p>
              UAIC employees are made aware of its privacy practices through
              periodic communications and training. We limit access to private
              and confidential data only to those employees with a specific need
              to retrieve this information and we store data on multiple service
              systems, in controlled, secure environments. To maintain our level
              of security, we conduct both internal reviews and external audits
              of our security measures and procedures on a regular basis.
            </p>

            <br></br>
            <br></br>

            <h3 id="structure">Changes in Corporate Structure</h3>
            <hr></hr>
            <p>
              In developing our business, we might sell certain assets or the
              company, or parts of it, may be sold, merged or otherwise
              transferred. In such transactions, user information, whether
              personally identifiable or otherwise, may be one of the
              transferred assets.
            </p>

            <br></br>
            <br></br>

            <h3 id="visitors">Visitors Outside The United States</h3>
            <hr></hr>
            <p>
              Our Web site and the servers that make this site available
              worldwide are located in the United States. All matters relating
              to these sites are governed by the laws of the State of New York
              in the United States. Any information you provide will be
              transferred to the United States. By visiting our sites and
              submitting information, you authorize this transfer.
            </p>

            <br></br>
            <br></br>

            <h3 id="changes">Changes to Our Privacy Policy</h3>
            <hr></hr>
            <p>
              We will occasionally update this Website Privacy Policy as
              necessary to maintain the highest protection for our users and to
              comply with a changing environment. We recommend that you check
              the policy when you visit our site to be sure you are aware of and
              understand our current policy. If we make material changes to this
              policy or in how we will use or collect your personally
              identifiable information, we will prominently post such changes
              prior to implementing the change. We will not make changes in how
              we handle previously collected personal information that have
              retroactive effect unless legally required to do so.
            </p>

            <br></br>
            <br></br>

            <h3 id="contact">Contact Information</h3>
            <hr></hr>
            <p>
              If you have any questions about our Website Privacy Policy or our
              website privacy practices, please visit us at our{" "}
              <Link to="/uaichome/contact">contact us</Link> section.
              <br></br>
              <br></br>
              {/* <p>Last updated 2008</p> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
