import React, { useState } from "react";
import "./videosCarousel.css";

import { ImPlay } from "react-icons/im";
import Iframe from "react-iframe-click";
import GA from "../../functions/GA";

const VideoThumbnail = ({ thumbnailImage, videoUrl, text }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <div className="video-item-container">
      <div
        onMouseEnter={() => {
          // To hide image and show video on hover
          setShowVideo(true);
        }}
        onMouseLeave={() => {
          if (!isVideoPlaying) {
            // on mouse leave if not playing show image again
            setShowVideo(false);
          }
        }}
        className="video-and-thumbnail-container"
      >
        {!showVideo && (
          <div className="thumbnail-overlay">
            <div className="thumbnail-overlay-content">
              <ImPlay />
            </div>
          </div>
        )}

        <Iframe
          className={
            !showVideo ? "iframe-hidden-thumbnail" : "iframe-shown-thumbnail"
          }
          src={videoUrl}
          frameborder="0"
          allow="fullscreen"
          allowFullScreen
          title="video 1"
          onInferredClick={() => {
            console.log(`User clicked on claims portal video: ${text}`);
            GA(
              "Claims_Portal_videos",
              `User clicked on claims portal video: ${text}`
            );
            setIsVideoPlaying(true);
          }}
        ></Iframe>
        <div className="video-thumbnail-loading-container">
          <span>Loading video...</span>
        </div>

        <img
          className={showVideo ? "img-hidden-thumbnail" : "img-shown-thumbnail"}
          alt={text}
          src={thumbnailImage}
        />
      </div>

      <div className="video-item-content">
        <p>How to:</p>
        <h1>{text}</h1>
      </div>
    </div>
  );
};

export default VideoThumbnail;
