import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import React from 'react'

const SEO_Headers = () => {
  const selectedPagetoDisplay = useSelector(
    (state) => state.selectedPagetoDisplayReducer
  );

  let SEO_TitleofPage =
    "UAIC | United Auto | United Automobile Insurance Company | Car Insurance | High Quality and Low Cost Insurance";
  if (
    selectedPagetoDisplay === "Home_Page" ||
    selectedPagetoDisplay === "HOME_PAGE_M" ||
    selectedPagetoDisplay === "HOME_PAGE_D"
  ) {
    SEO_TitleofPage =
      "UAIC | United Auto | United Automobile Insurance Company | Car Insurance | High Quality and Low Cost Insurance";
  }
  if (
    selectedPagetoDisplay === "ABOUT_US_M" ||
    selectedPagetoDisplay === "ABOUT_US_D"
  ) {
    SEO_TitleofPage =
      "UAIC | About Us | United Automobile Insurance Company | Auto Insurance";
  }
  if (selectedPagetoDisplay === "BAA_M" || selectedPagetoDisplay === "BAA_D") {
    SEO_TitleofPage =
      "UAIC | Become an Agent | United Automobile Insurance Company | Auto Insurance";
  }
  if (
    selectedPagetoDisplay === "INSURANCE_M" ||
    selectedPagetoDisplay === "INSURANCE_D"
  ) {
    SEO_TitleofPage =
      "UAIC | Insurance- Auto Insurance and Commercial Auto Insurance";
  }
  if (
    selectedPagetoDisplay === "CAREERS_M" ||
    selectedPagetoDisplay === "CAREERS_D"
  ) {
    SEO_TitleofPage =
      "UAIC | Careers | Benefits, Diversity, FAQ, Job Fairs, Accounting, Claims, Corporate and Executive, Information Technology, Underwritting.";
  }
  if (
    selectedPagetoDisplay === "CLAIMS_M" ||
    selectedPagetoDisplay === "CLAIMS_D"
  ) {
    SEO_TitleofPage =
      "UAIC | Claim Center | United Automobile Insurance Company | Miami, Hialeah, Broward, Dallas, Houston, Austin, San Antonio";
  }
  if (
    selectedPagetoDisplay === "CONTACT_US_M" ||
    selectedPagetoDisplay === "CONTACT_US_M"
  ) {
    SEO_TitleofPage =
      "UAIC | Contact Us | United Automobile Insurance Company | Miami, Hialeah, Broward, Dallas, Houston, Austin, San Antonio";
  }
  if (
    selectedPagetoDisplay === "LIENHOLDER_M" ||
    selectedPagetoDisplay === "LIENHOLDER_D"
  ) {
    SEO_TitleofPage =
      "UAIC | Lienholder Search | United Automobile Insurance Company";
  }
  if (selectedPagetoDisplay === "NEW_USER") {
    SEO_TitleofPage = "UAIC | NEW USER | United Automobile Insurance Company";
  }

  return (
    <Helmet>
      <title>{SEO_TitleofPage}</title>
      <meta
        name="keywords"
        content="Car Insurance, Auto Insurance, UAIC, UAIG, UAIS, Insurance, online car insurance, discount car insurance, cheap car insurance, www.uaig.com, www.uaig.net, UAIC insurance, UAIC car insurance, UAIC auto insurance, Miami, Miami gardens, Davie, Pembroke Pines, Miramar, Aventura, Sunny Isles, Doral "
      />
      <meta
        name="description"
        content="UAIC, United Automobile Insurance Company is a property and casualty insurance organization specializing in automobile insurance. We are committed to providing our agents and their customers, a quality product with state-of-the-art processing and claims service. Through our affiliates we provide a variety of insurance related services, including premium finance, claims processing and advanced point of sale solutions."
      />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
    </Helmet>
  );
};

export default SEO_Headers;
