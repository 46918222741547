import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter as Router } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./reducers/rootReducers";
import ReactGA from "react-ga4";
import selectCorrectUrls from "./selectCorrectUrls";
import { HashRouter } from "react-router-dom";
// import GoogleAnalytics from "./googleAnalytics";

ReactGA.initialize([ {
  trackingId:selectCorrectUrls().googleAnalytics,
  debug: true, //set to true to show more info in the console when calling the ga function
  titleCase: false,
  gaOptions: {
    gaAddress: "uaig.net",
  },
}]);

// ReactGA.pageview(window.location.pathname + window.location.search);


ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search  });

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  localStorage.setItem("reduxStateUAIG", JSON.stringify(store.getState()));
});

store.subscribe(() => store.getState());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <GoogleAnalytics /> */}
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
