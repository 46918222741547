import React from "react";
import "./leinHolder.css";
import selectCorrectUrls from "../../selectCorrectUrls";

const LeinHolder = () => {
  return (
    <div>
      <div style={{ marginBottom: "2rem" }} className="about-uaic-box">
        <i style={{ marginLeft: "2rem" }} className="fas fa-user-tie fa-2x"></i>
        <h2 style={{ marginLeft: "1rem" }}>LienHolder</h2>
      </div>
      <div className="new-user-iframe">
        <iframe
          style={{
            border: "none",
            overscrollBehaviorY: "none",
          }}
          title="Lien holder search"
          width="95%"
          height="700"
          scrolling="no"
          src={`${
            selectCorrectUrls().insuredPortal
          }/insurednew/insured/lien/check`}
        ></iframe>
      </div>

      {/* <form>
        <div className="box">
          <div className="leinInput">
            <div className="leinFieldset">
              <span className="search">New Search</span>

              <fieldset>
                <label>Enter Policy Number</label>
                <br />
                <input type="text" />
                <br />
                <label>Last 5 Digits of Vin Number</label>
                <br />
                <input type="text" />
                <br />
                <button>Verify</button>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
      <div className="leinDetails">
        <span className="logout">
          <a href="/">Logout</a>
        </span>
        <center>
          <h1>Lienholder Verification Details</h1>
        </center>
        <div className="row1">
          <div className="half-left">
            <p>Policy Number:</p>
          </div>
          <div className="middle-left">
            <p>Last 5 Digits of Vin #:</p>
          </div>
          <div className="third-left">
            <p>
              Agent Name: <br /> Agent Address: <br />
              Agent Phone:
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LeinHolder;
