import React, { useState, useEffect } from "react";
import "./accounting.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Accounting = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={isComponentLoaded ? "Accounting" : "Accounting-not-rendered"}
    >
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          src="/uaichome/pictures/accounting.png"
          alt="A pen next to a calculator"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Accounting</h2>
        <hr></hr>
        <div>
          <p>
            Accounting, auditing, and bookkeeping services serve key functions
            in America's (and the world's) economic engine. In such publications
            as The Bottom Line, the global significance of the accounting
            profession has long been confirmed: The world's capital markets rely
            on financial statements certified by independent auditors. If the
            integrity of those statements could not be trusted, investment would
            come to a halt and economic growth would be paralyzed.
          </p>
          <br></br>
          <p>
            If you choose to join this dynamic department, you will be assisting
            in safeguarding our assets, providing timely and accurate external
            reporting, optimizing cash flow and presenting financial analysis of
            trends and key issues. With our use of advanced technology, employee
            skills and experience, we help develop financial solutions to assist
            in the success of UAIC.
          </p>
          <br></br>
          <p>
            Currently we are not hiring in the accounting department. For a
            complete list of current opportunities, please{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=85b114ae-da67-42eb-bd2b-36c970ea9207&ccId=19000101_000001&type=MP&lang=en_US"
            >
              click here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accounting;
