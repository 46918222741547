import React, { useState, useEffect } from "react";
import "./it.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const It = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div className={isComponentLoaded ? "It" : "It-not-rendered"}>
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          src="/uaichome/pictures/it.png"
          alt="To man looking at a server computer"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Information Technology</h2>
        <hr></hr>
        <div>
          <p>
            Not only is UAIC one of the fastest growing casualty insurance
            companies in the United States, we are also a hi-tech powerhouse. By
            using the latest technology, our Miami Gardens based IT team works
            diligently to position UAIC as a leader in our industry.
          </p>
          <br></br>
          <p>
            In IT, we design the technology solutions that have improved online
            customer service, and claims experience to our customers, while
            maintaining a user-friendly environment. UAIC offers rewarding,
            full-time IT job opportunities within our systems operations,
            information services, and internet business units.
          </p>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default It;
