import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./header.css";
import Axios from "axios";
import selectCorrectUrls from "../../selectCorrectUrls";
import { isMobile } from "react-device-detect";
import GA from "../functions/GA";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import {
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  FormLabel,
  FormControl,
} from "@material-ui/core";
import useScapePressed from "../../functions/useScapePressed";
import focusOnPupop from "../../functions/focusOnPopup";

const override = css`
  display: flex;
  margin: auto;
  color: white;
  margin-top: -7px;
`;

const Header = () => {
  const dispatch = useDispatch();
  const scapePressed = useScapePressed();

  const jwt = useSelector((state) => state.jwtReducer);
  const isRequestHelpOpen = useSelector((state) => state.requestHelpReducer);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [agentUsername, setAgentUserName] = useState("");
  const [agentPassword, setAgentPassword] = useState("");
  const [customerUsername, setCustomerUsername] = useState("");
  const [customerPassword, setCustomerPassword] = useState("");
  const [isAgentDisabled, setAgentDisabled] = useState(true);
  const [isCustomerDisabled, setCustomerDisabled] = useState(true);
  const [windowWidth, setWindowWidth] = useState();
  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [agentActionUrl, setAgentActionUrl] = useState("");
  const [isForgotPasswordTimerAgent, setIsForgotPasswordTimerAgent] =
    useState(false);
  const [forgotPasswordTimerSecondsAgent, setForgotPasswordTimerSecondsAgent] =
    useState(120);
  const [isForgotPasswordTimerCustomer, setIsForgotPasswordTimerCustomer] =
    useState(false);
  const [
    forgotPasswordTimerSecondsCustomer,
    setForgotPasswordTimerSecondsCustomer,
  ] = useState(120);
  const [isMessage, setIsMessage] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isForgotPasswordAgent, setIsForgotPasswordAgent] = useState(false);
  const [isForgotPasswordCustomer, setIsForgotPasswordCustomer] =
    useState(false);
  const [isconfirmLoginMessage, setIsConfirmLoginMessage] = useState(false);
  const [correctNamesAndIds, setCorrectNamesAndIds] = useState({
    username: "",
    password: "",
    nameUserId: "",
    namePassword: "",
  });
  const [correctNamesAndIdsCustomer, setCorrectNamesAndIdsCustomer] = useState({
    username: "",
    password: "",
    nameUserId: "",
    namePassword: "",
  });
  const [selectedRadioButtonCustomer, setSelectedRadioButtonCustomer] =
    useState("insured_login");

  const [forgotPasswordAgent, setForgotPasswordAgent] = useState("");
  const [forgotPasswordCustomer, setForgotPasswordCustomer] = useState("");
  const [config, setConfig] = useState({
    headers: {
      "Content-Type": "text/html",
    },
    responseType: "text",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [weather_message_html, set_weather_message_html] =
    useState(`<p>Hello there</p>`);

  let location = useLocation();
  location = location.pathname;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        if (isRequestHelpOpen) {
          dispatch({
            type: "SET_REQUEST_HELP",
            payload: false,
          });
        }
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isRequestHelpOpen]);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    window.addEventListener("scroll", () =>
      setWindowScrollPosition(window.scrollY)
    );
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (jwt === null) {
      dispatch({
        type: "SET_IS_LOGED_IN",
        payload: false,
      });
    }
  }, [jwt]);

  useEffect(() => {
    Axios.post() //we chekc here if the jwt is still valid
      .then((response) => {
        const isValid = response.data.isValid;

        if (isValid) {
          dispatch({
            type: "SET_IS_LOGED_IN",
            payload: true,
          });
        } else {
          dispatch({
            type: "SET_IS_LOGED_IN",
            payload: false,
          });
          dispatch({
            type: "SET_JWT",
            payload: null,
          });
        }
      })
      .catch((e) => {
        if(e === undefined){
        }else{
          console.log(e);
        }
      });
  }, []);

  //display the error message just for 5 seconds
  useEffect(() => {
    if (isErrorMessage === true) {
      setTimeout(() => {
        setIsErrorMessage(false);
      }, 5000);
    } else if (isMessage === true) {
      setTimeout(() => {
        setIsMessage(false);
      }, 5000);
    }
  }, [isErrorMessage, isMessage]);

  useEffect(() => {
    if (selectedRadioButtonCustomer === "claims_login") {
      setCorrectNamesAndIdsCustomer({
        id: "cusername",
        nameUserId: "cusername",
        password: "cpassword",
        namePassword: "cpassword",
      });
    } else if (selectedRadioButtonCustomer === "insured_login") {
      setCorrectNamesAndIdsCustomer({
        id: "username",
        nameUserId: "username",
        password: "password",
        namePassword: "password",
      });
    }
  }, [selectedRadioButtonCustomer]);

  function getUrlParams(search) {
    const hashes = search.slice(search.indexOf("?") + 1).split("&");
    const params = {};
    hashes.map((hash) => {
      const [key, val] = hash.split("=");
      params[key] = decodeURIComponent(val);
    });
    return params;
  }

  useEffect(() => {
    if (getUrlParams(window.location.hash).campaign) {
      dispatch({
        type: "SET_CAMPAIGN",
        payload: getUrlParams(window.location.hash).campaign,
      });
    }

    if (getUrlParams(window.location.hash).agent === "undefined") {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
      document.getElementById("agent_portal_radio").click();
    } else if (getUrlParams(window.location.hash).customer === "undefined") {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
      document.getElementById("customer_portal_radio").click();
    } else if (
      getUrlParams(window.location.hash).customerClaims === "undefined"
    ) {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
      document.getElementById("customer_portal_radio").click();
      setSelectedRadioButtonCustomer("claims_login");
    } else if (getUrlParams(window.location.hash).login === "undefined") {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
    } else if (
      getUrlParams(window.location.hash).forgotPasswordAgent === "undefined"
    ) {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
      document.getElementById("agent_portal_radio").click();

      // setIsForgotPasswordAgent(true);
    } else if (
      getUrlParams(window.location.hash).forgotPasswordCustomer === "undefined"
    ) {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
      document.getElementById("customer_portal_radio").click();
      //  setIsForgotPasswordCustomer(true);
    }
  }, []);

  const customerValidPost = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let url = `${
      selectCorrectUrls().insuredPortal
    }/insurednew/insured/logincheck/customer?username=${customerUsername}&password=${encodeURIComponent(
      customerPassword
    )}`;

    Axios.get(url, config)
      .then((response) => {
        setIsLoading(false);
        let data = response.data.replace(/^\s+|\s+$/g, "");
        // console.log(response);
        if (data === "OK") {
          GA(
            "CUSTOMER_LOGIN_SUCCESSFUL",
            `${customerUsername} - Successfully logged in Insured Portal`
          );

          setIsMessage(true);
          setMessage("You have Successfully Logged In");
          document.getElementById("mainFormCustomer").submit();
          return true;
        } else {
          GA(
            "CUSTOMER_LOGIN_FAILED",
            `${customerUsername} -  Failed while trying to log into the Insured Portal`
          );

          setIsErrorMessage(true);
          setMessage(data);
          return false;
        }
      })
      .catch((e) => {
        setIsLoading(false);
        GA(
          "CUSTOMER_SYSTEM_FAILURE",
          `${customerUsername} -  System failure while trying to log into the Insured Portatl`
        );

        setIsErrorMessage(true);
        setMessage("System Failure: Please try after some time.");

        // console.log(e);
      });
  };

  const agentValidPost = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let url = `${
      selectCorrectUrls().insuredPortal
    }/uaicins/agent/login/validate?tbxUserID=${agentUsername}&tbxPassword=${encodeURIComponent(
      agentPassword
    )}`;

    let urlWeather =
      "https://www.uaig.net/uaichome/static/msgs/fl_message.html";

    Axios.get(url, config)
      .then((response) => {
        setAgentActionUrl(response.data.url);

        if (
          response.data.url ===
            `${
              selectCorrectUrls().florida
            }/agents/ndmacro/cmn_Login.mac/CheckLogin` ||
          response.data.url ===
            `${
              selectCorrectUrls().georgia
            }/agents/ndmacro/cmn_Login.mac/CheckLogin`
        ) {
          setCorrectNamesAndIds({
            id: "tbxUserID",
            password: "tbxPassword",
            nameUserId: "tbxUserID",
            namePassword: "tbxPassword",
          });

          urlWeather =
            "https://www.uaig.net/uaichome/static/msgs/fl_message.html";
        } else if (
          response.data.url ===
          `${selectCorrectUrls().texas}/agents/cgi-dta/ilogin.mac/CheckLogin`
        ) {
          setCorrectNamesAndIds({
            id: "UserID",
            nameUserId: "UserID",
            password: "Password",
            namePassword: "Password",
          });

          urlWeather =
            "https://www.uaig.net/uaichome/static/msgs/tx_message.html";
        }

        Axios.post(urlWeather)
          .then((response_weather) => {
            setIsLoading(false);

            set_weather_message_html(response_weather.data);
            if (response.data.status === 1) {
              if (response_weather.data !== ".") {
                setIsConfirmLoginMessage(true);
              } else {
                GA(
                  "AGENT_LOGIN_SUCCESSFUL",
                  `${agentUsername} - Succesfully logged into Agent Portal`
                );

                setIsMessage(true);
                setMessage(response.data.msg);
                document.getElementById("mainFormAgent").submit();
              }
            } else {
              e.preventDefault();

              GA(
                "AGENT_LOGIN_FAILED",
                `${agentUsername} - Agent failed to logged into Agent Portal`
              );

              setIsErrorMessage(true);
              setMessage(response.data.msg);
            }
          })
          .catch((e_weather) => {
            setIsLoading(false);

            if (response.data.status === 1) {
              setIsMessage(true);
              setMessage(response.data.msg);

              setTimeout(() => {
                //document.getElementById("mainFormAgent").submit();
              }, 1000);
            } else {
              e.preventDefault();

              setIsErrorMessage(true);
              setMessage(response.data.msg);
            }
          });
      })
      .catch((e) => {
        setIsLoading(false);

        //set a custom error message in the div  : System Failure: Please try after some time.

        GA(
          "AGENT_SYSTEM_FAILURE",
          `${customerUsername} -  System failure while trying to log into the Aent Portatl`
        );

        setIsErrorMessage(true);
        setMessage("System Failure: Please try after some time.");

        // console.log(e);
      });
  };

  //timer for the forgot password

  useEffect(() => {
    if (isForgotPasswordTimerAgent) {
      let countDown = forgotPasswordTimerSecondsAgent;
      let interval = setInterval(() => {
        countDown--;
        setForgotPasswordTimerSecondsAgent(
          (forgotPasswordTimerSecondsAgent) =>
            forgotPasswordTimerSecondsAgent - 1
        );

        if (countDown <= 0) {
          clearInterval(interval);
          setIsForgotPasswordTimerAgent(false);
        }
      }, 1000);
    }
  }, [isForgotPasswordTimerAgent]);

  useEffect(() => {
    if (isForgotPasswordTimerCustomer) {
      let countDown = forgotPasswordTimerSecondsCustomer;
      let interval = setInterval(() => {
        countDown--;
        setForgotPasswordTimerSecondsCustomer(
          (forgotPasswordTimerSecondsCustomer) =>
            forgotPasswordTimerSecondsCustomer - 1
        );

        if (countDown <= 0) {
          clearInterval(interval);
          setIsForgotPasswordTimerCustomer(false);
        }
      }, 1000);
    }
  }, [isForgotPasswordTimerCustomer]);

  const sendForgotPasswordEmail = (e, where) => {
    e.preventDefault();
    setIsLoading(true);

    setIsForgotPasswordAgent(false);
    setIsForgotPasswordCustomer(false);

    //this is a test url, once we have the correct service we can send emails to the email that the user typed

    let url = "";

    if (where === "agent") {
      url = `${
        selectCorrectUrls().insuredPortal
      }/uaicins/agent/forgotpassword/index?agentid=${forgotPasswordAgent}`;

      Axios.get(url, config)
        .then((response) => {
          setIsLoading(false);

          if (response.data.status == 1) {
            setIsMessage(true);

            setMessage(
              "If a valid email is found we would be sending instructions to reset password in the email."
            );
            GA(
              "FORGOT_PASSWORD_AGENT",
              `${forgotPasswordAgent} - Successfully requested to change password`
            );

            setForgotPasswordTimerSecondsAgent(120);
            setIsForgotPasswordTimerAgent(true);
          } else if (response.data.status == 0) {
            GA(
              "FORGOT_PASSWORD_AGENT_FAILED",
              `${forgotPasswordAgent} - Failed to request to change password`
            );

            setIsErrorMessage(true);
            setMessage(response.data.msg);
          }
        })
        .catch((e) => {
          setIsLoading(false);

          // console.log(e);
          setIsForgotPasswordAgent(false);
          setIsForgotPasswordCustomer(false);
          setIsErrorMessage(true);
          setMessage("Something went wrong, try again later.");
        });
    } else if (where === "customer") {
      url = `${
        selectCorrectUrls().insuredPortal
      }/insurednew/insured/pms/fp?email-id=${forgotPasswordCustomer}`;

      Axios.get(url)
        .then((response) => {
          setIsLoading(true);

          // console.log(response);

          setIsForgotPasswordAgent(false);
          setIsForgotPasswordCustomer(false);

          if (response.data.status == 1) {
            setIsMessage(true);
            setMessage(
              "If a valid email is found we would be sending instructions to reset password in the email."
            );
            GA(
              "FORGOT_PASSWORD_CUSTOMER",
              `${forgotPasswordCustomer} - Successfully requested to change password`
            );

            setForgotPasswordTimerSecondsAgent(120);
            setIsForgotPasswordTimerAgent(true);
          } else if (response.data.status == 0) {
            GA(
              "FORGOT_PASSWORD_CUSTOMER_FAILED",
              `${forgotPasswordCustomer} - Failed to request to change password`
            );

            setIsMessage(true);
            setMessage(response.data.displaymsg);
            setForgotPasswordTimerSecondsCustomer(120);
            setIsForgotPasswordTimerCustomer(true);
          }
        })
        .catch((e) => {
          setIsLoading(false);

          // console.log(e);
          setIsForgotPasswordAgent(false);
          setIsForgotPasswordCustomer(false);
          setIsErrorMessage(true);
          setMessage("Something went wrong, try again later.");
        });
    }
  };

  useEffect(() => {
    if (agentUsername !== "" && agentPassword !== "") {
      setAgentDisabled(false);
    } else if (agentPassword !== "" && agentUsername !== "") {
      setAgentDisabled(false);
    } else setAgentDisabled(true);
  }, [agentUsername, agentPassword]);

  useEffect(() => {
    if (customerUsername !== "" && customerPassword !== "") {
      setCustomerDisabled(false);
    } else if (customerPassword !== "" && customerUsername !== "") {
      setCustomerDisabled(false);
    } else setCustomerDisabled(true);
  }, [customerUsername, customerPassword]);

  const isLoginMenuOpen = useSelector((state) => state.isLoginMenuOpenReducer);
  const isMobileMenuOpen = useSelector(
    (state) => state.isMobileMenuOpenReducer
  );
  const isAgentsLoginMenuOpen = useSelector(
    (state) => state.isAgentsLoginMenuOpenReducer
  );
  const isCustomerLoginMenuOpen = useSelector(
    (state) => state.isCustomerLoginMenuOpenReducer
  );
  // const selectedPagetoDisplay = useSelector(
  //   (state) => state.selectedPagetoDisplayReducer
  // );

  const selectedLogintoDisplay = useSelector(
    (state) => state.selectedLogintoDisplayReducer
  );

  const [LoginDisplay, setLoginDisplay] = useState("AGENT");

  // const [selectedLoginButtonCustomer, setselectedLoginButtonCustomer] =
  //   useState("CUSTOMER");
  // const [selectedLoginButtonAgent, setselectedLoginButtonAgent] =
  //   useState("AGENT");

  const [isAgent, setIsAgent] = useState(true);
  const [isCustomer, setIsCustomer] = useState(false);

  //console.log(selectedPagetoDisplay);
  const checkAboutUsClassName = (path) => {
    if (location === path) {
      return "navigation-button-aboutUs-active";
    } else return "navigation-button-aboutUs-off";
  };
  const checkClaimsClassName = (path) => {
    if (location === path) {
      return "navigation-button-claims-active";
    } else return "navigation-button-claims-off";
  };
  const checkBecomeAgentClassName = (path) => {
    if (location === path) {
      return "navigation-button-become-agent-active";
    } else return "navigation-button-become-agent-off";
  };
  const checkInsuranceClassName = (path) => {
    if (location === path) {
      return "navigation-button-insurance-active";
    } else return "navigation-button-insurance-off";
  };
  const checkCareersClassName = (path) => {
    if (location === path) {
      return "navigation-button-career-active";
    } else return "navigation-button-career-off";
  };
  const checkContactClassName = (path) => {
    if (location === path) {
      return "navigation-button-contact-active";
    } else return "navigation-button-contact-off";
  };
  const checkLeinHolder = (path) => {
    if (location === path) {
      return "navigation-button-leinholder-active";
    } else return "navigation-button-leinholder-off";
  };

  const checkAboutUsClassNameMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-aboutUs-active";
      } else return "navigation-button-aboutUs-off";
    } else return "navigation-button-hidden";
  };
  const checkClaimsClassNameMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-claims-active";
      } else return "navigation-button-claims-off";
    } else return "navigation-button-hidden";
  };
  const checkBecomeAgentClassNameMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-become-agent-active";
      } else return "navigation-button-become-agent-off";
    } else return "navigation-button-hidden";
  };
  const checkInsuranceClassNameMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-insurance-active";
      } else return "navigation-button-insurance-off";
    } else return "navigation-button-hidden";
  };
  const checkCareersClassNameMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-career-active";
      } else return "navigation-button-career-off";
    } else return "navigation-button-hidden";
  };
  const checkContactClassNameMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-contact-active";
      } else return "navigation-button-contact-off";
    } else return "navigation-button-hidden";
  };
  const checkLeinHolderMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-leinholder-active";
      } else return "navigation-button-leinholder-off";
    } else return "navigation-button-hidden";
  };
  const checkFindAnAgentMobile = (path) => {
    if (isMenuOpened) {
      if (location === path) {
        return "navigation-button-find-an-agent-active";
      } else return "navigation-button-find-an-agent-off";
    } else return "navigation-button-hidden";
  };

  const checkDisabledButtonAgent = () => {
    if (isAgentDisabled) {
      return "home-page-button-off";
    } else return "home-page-button";
  };

  const checkDisabledButtonCustomer = () => {
    if (isCustomerDisabled) {
      return "home-page-button-off";
    } else return "home-page-button";
  };

  const closeLoginMenu = () => {
    if (isLoginMenuOpen === true) {
      dispatch({
        type: "OPEN_LOGIN_MENU",
      });
    } else if (isAgentsLoginMenuOpen === true) {
      dispatch({
        type: "OPEN_AGENTS_LOGIN_MENU",
      });
    } else if (isCustomerLoginMenuOpen === true) {
      dispatch({
        type: "OPEN_CUSTOMER_LOGIN_MENU",
      });
    }
  };

  const scrollToTop = (paddingTop) => {
    window.scrollTo({
      top: paddingTop,
      behavior: "smooth",
    });
  };

  const getCurrentYear = () => {
    const date = new Date();

    return date.getFullYear();
  };

  const errorMessageComponent = () => {
    return (
      <div
        className={
          isErrorMessage ? "error-message-shown" : "error-message-hidden"
        }
      >
        <div></div>
        <h3>{message}</h3>
      </div>
    );
  };

  const messageComponent = () => {
    return (
      <div className={isMessage ? "message-shown" : "message-hidden"}>
        <div></div>
        <h3>{message}</h3>
      </div>
    );
  };

  const forgotPasswordComponentAgent = () => {
    return (
      <div>
        <div
          onClick={() => {
            setIsForgotPasswordCustomer(false);
            setIsForgotPasswordAgent(false);
          }}
          className={
            isForgotPasswordAgent
              ? "forgot-password-background-on"
              : "forgot-password-background-off"
          }
        ></div>
        <div
          className={
            isForgotPasswordAgent
              ? "forgot-password-component-shown"
              : "forgot-password-component-hidden"
          }
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Forgot your password?</h3>
            <h2
              className="close-login-menu-icon"
              onClick={() => {
                setIsForgotPasswordCustomer(false);
                setIsForgotPasswordAgent(false);
              }}
            >
              <i
                className="fa fa-times-circle"
                style={{ fontSize: "1em", color: "#01224f" }}
                aria-hidden="true"
              ></i>
              &nbsp;
            </h2>
          </div>

          <hr></hr>
          <form
            onSubmit={(e) => {
              sendForgotPasswordEmail(e, "agent");
            }}
          >
            <div style={{ marginTop: "1rem" }}>
              <h4>Enter your Username:</h4>
              <TextField
                placeholder="Username"
                label="Username"
                variant="outlined"
                autoFocus
                required
                fullWidth
                value={forgotPasswordAgent}
                onChange={(e) => {
                  setForgotPasswordAgent(e.target.value);
                }}
                style={{ marginTop: "12px" }}
              />
              {/* <input
                type="text"
                maxLength="20"
                required
                placeholder="EX: jondoe123"
                value={forgotPasswordAgent}
                onChange={(e) => {
                  setForgotPasswordAgent(e.target.value);
                }}
              ></input> */}
            </div>
            <button
              disabled={isForgotPasswordTimerAgent}
              type="submit"
              style={
                isForgotPasswordTimerAgent
                  ? {
                      backgroundColor: "rgba(220,220,220)",
                      marginTop: "10px",
                      cursor: "default",
                      pointerEvents: "none",
                      color: "black",
                    }
                  : { marginTop: "10px" }
              }
              className="home-page-button"
            >
              {isForgotPasswordTimerAgent
                ? forgotPasswordTimerSecondsAgent
                : "Submit"}
            </button>
          </form>
        </div>
      </div>
    );
  };

  const forgotPasswordComponentCustomer = () => {
    return (
      <div>
        <div
          onClick={() => {
            setIsForgotPasswordAgent(false);
            setIsForgotPasswordCustomer(false);
          }}
          className={
            isForgotPasswordCustomer
              ? "forgot-password-background-on"
              : "forgot-password-background-off"
          }
        ></div>
        <div
          className={
            isForgotPasswordCustomer
              ? "forgot-password-component-shown"
              : "forgot-password-component-hidden"
          }
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Forgot your password?</h2>
            <h2
              className="close-login-menu-icon"
              onClick={() => {
                setIsForgotPasswordCustomer(false);
                setIsForgotPasswordAgent(false);
              }}
            >
              <i
                className="fa fa-times-circle"
                style={{ fontSize: "1.5em", color: "#01224f" }}
                aria-hidden="true"
              ></i>{" "}
              &nbsp;
            </h2>
          </div>

          <hr></hr>
          <form
            onSubmit={(e) => {
              sendForgotPasswordEmail(e, "customer");
            }}
          >
            <div style={{ marginTop: "1rem" }}>
              <TextField
                placeholder="Email"
                label="Enter your Email"
                variant="outlined"
                autoFocus
                required
                fullWidth
                value={forgotPasswordCustomer}
                onChange={(e) => {
                  setForgotPasswordCustomer(e.target.value);
                }}
                style={{ marginTop: "12px" }}
              />
            </div>
            <button
              disabled={isForgotPasswordTimerCustomer}
              type="submit"
              style={
                isForgotPasswordTimerCustomer
                  ? {
                      backgroundColor: "rgba(220,220,220)",
                      marginTop: "10px",
                      cursor: "default",
                      pointerEvents: "none",
                      color: "black",
                    }
                  : { marginTop: "10px" }
              }
              className="home-page-button"
            >
              {isForgotPasswordTimerCustomer
                ? forgotPasswordTimerSecondsCustomer
                : "Submit"}
            </button>
          </form>
        </div>
      </div>
    );
  };

  const confirmLoginMessage = () => {
    return (
      <div>
        <div
          className={
            isconfirmLoginMessage
              ? "forgot-password-background-on"
              : "forgot-password-background-off"
          }
        ></div>
        <div
          className={
            isconfirmLoginMessage
              ? "confirm-login-message-component-open"
              : "confirm-login-message-component-closed"
          }
        >
          <div>
            <h1>Read before continue</h1>
            <hr></hr>
            <div
              className="weather-message"
              dangerouslySetInnerHTML={{ __html: weather_message_html }}
              style={{ overflowY: "scroll", height: "150px" }}
            ></div>
            <hr></hr>
            <div className="continue-button-confirm-login">
              <button
                onClick={() => {
                  document.getElementById("mainFormAgent").submit();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const selectCorrectActionForCustomers = () => {
    if (isMobile) {
      if (selectedRadioButtonCustomer === "insured_login") {
        return `${selectCorrectUrls().insuredPortal}/mobile/login`;
      } else if (selectedRadioButtonCustomer === "claims_login") {
        return `${
          selectCorrectUrls().insuredPortal
        }/insurednew/insured/claimuser/clogin`;
      }
    } else {
      if (selectedRadioButtonCustomer === "insured_login") {
        return `${selectCorrectUrls().insuredPortal}/insurednew/login`;
      } else if (selectedRadioButtonCustomer === "claims_login") {
        return `${
          selectCorrectUrls().insuredPortal
        }/insurednew/insured/claimuser/clogin`;
      }
    }
  };

  if (selectedLogintoDisplay === "CUSTOMER") {
    // document.getElementById("Customers-Div").style.display = "block";
    // document.getElementById("Agents-Div").style.display = "none";
  }
  if (selectedLogintoDisplay === "AGENT") {
    // document.getElementById("Customers-Div").style.display = "none";
    // document.getElementById("Agents-Div").style.display = "block";
  }

  useEffect(() => {
    if (isLoginMenuOpen) {
      closeLoginMenu();
    }
  }, [scapePressed]);

  useEffect(() => {
    if (isLoginMenuOpen) {
      focusOnPupop({ id: "login-popup-menu-container" });
    }
  }, [isLoginMenuOpen, selectedLogintoDisplay]);

  const loginMenu = () => {
    return (
      <div id="login-popup-menu-container">
        <div
          id={selectedLogintoDisplay === "BOTH" ? "STRETCH-ME" : ""}
          className={isLoginMenuOpen ? "login-box-opened" : "login-box-closed"}
        >
          <div
            className="login-container"
            style={{
              textAlign: "center",
              display: "grid",
              margin: "0 auto",
            }}
            id={!isMobile ? "HIDE-ME" : "HIDE-ME"}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ display: "none" }}>
                Select
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <div className="grid-container">
                  <div className="grid-item popup" style={{ padding: "10px" }}>
                    <h2
                      className="blue-color"
                      style={{ marginBottom: "-30px" }}
                    >
                      Agent Login
                    </h2>
                    <br></br>
                    <FormControlLabel
                      control={<Radio className="HIDE-ME" color="primary" />}
                      label={
                        <img
                          alt="Icon of an agent with headphones on"
                          src="/uaichome/pictures/agents.png"
                          className="agent-icon"
                          style={{
                            borderRadius: "50%",
                            border: "5px solid #01224f",
                            margin: "8%",
                            height: "115px",
                          }}
                        ></img>
                      }
                      className="radio"
                      onClick={() => {
                        setLoginDisplay("AGENT");
                        setIsAgent(true);
                        dispatch({
                          type: "SET_LOGIN_PAGE_TO_DISPLAY",
                          payload: "AGENT",
                        });
                      }}
                      id="agent_portal_radio"
                      name="login_radio1"
                      value="insured_login1"
                      checked={selectedLogintoDisplay === "AGENT" && true}
                      style={{ marginBottom: "15px", marginTop: "15px" }}
                    />
                  </div>
                </div>
                <br></br>
                <div className="grid-item popup" style={{ padding: "10px" }}>
                  <h2 className="blue-color" style={{ marginBottom: "-20px" }}>
                    Customer Login
                  </h2>
                  <br></br>

                  <FormControlLabel
                    control={<Radio className="HIDE-ME" color="primary" />}
                    label={
                      <img
                        alt="Icon of an agent with headphones on"
                        src="/uaichome/pictures/Customer.png"
                        className="agent-icon"
                        style={{
                          borderRadius: "50%",
                          border: "5px solid #01224f",
                          margin: "8%",
                          height: "115px",
                        }}
                      ></img>
                    }
                    className="radio"
                    onClick={() => {
                      setLoginDisplay("CUSTOMER");
                      setIsCustomer(true);
                      dispatch({
                        type: "SET_LOGIN_PAGE_TO_DISPLAY",
                        payload: "CUSTOMER",
                      });
                    }}
                    id="customer_portal_radio"
                    name="login_radio1"
                    value="claims_login1"
                    checked={selectedLogintoDisplay === "CUSTOMER" && true}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          {errorMessageComponent()}
          {messageComponent()}
          {forgotPasswordComponentAgent()}
          {forgotPasswordComponentCustomer()}
          {confirmLoginMessage()}
          <div
            style={{
              textAlign: "center",
              alignItems: "center",
            }}
            id={selectedLogintoDisplay === "BOTH" ? "GRID-ME" : "GRID-ME"}
            className={!isMobile ? "MARG-50  " : ""}
          >
            <div
              // className="Agents-Div `${}"
              className={
                selectedLogintoDisplay === "CUSTOMER"
                  ? "HIDE-ME Agents-Div"
                  : "Agents-Div"
              }
              id={
                selectedLogintoDisplay === "AGENT" ? "EXTEND-ME" : "MINIMIZE-ME"
              }
              // id={checkLogintoDisplay("AGENT")}
              onClick={() => {
                // checkLogintoDisplay("AGENT");
                dispatch({
                  type: "SET_LOGIN_PAGE_TO_DISPLAY",
                  payload: "AGENT",
                });
              }}
            >
              <h2 className="blue-color">Agents Login</h2>
              <img
                alt="Icon of an agent with headphones on"
                src="/uaichome/pictures/agents.png"
                className="agent-icon"
              ></img>
              <div
                className="field-container"
                id={selectedLogintoDisplay === "AGENT" ? "SHOW-ME" : "HIDE-ME"}
              >
                <form
                  action={agentActionUrl}
                  name="mainFormAgent"
                  id="mainFormAgent"
                  method="POST"
                >
                  {" "}
                  <br></br>
                  <TextField
                    //autoComplete="fname"
                    id={correctNamesAndIds.id}
                    name={correctNamesAndIds.nameUserId}
                    value={agentUsername}
                    placeholder="Username"
                    label="Username"
                    variant="outlined"
                    autoFocus
                    required
                    fullWidth
                    onChange={(e) => {
                      setAgentUserName(e.target.value);
                    }}
                    style={{ marginTop: "12px" }}
                  />
                  <br></br>
                  <TextField
                    //autoComplete="fname"
                    id={correctNamesAndIds.password}
                    name={correctNamesAndIds.namePassword}
                    value={agentPassword}
                    type="password"
                    placeholder="Password"
                    label="Password"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(e) => {
                      setAgentPassword(e.target.value);
                    }}
                  />
                  <br></br>
                  <button
                    onClick={(e) => {
                      agentValidPost(e);
                      GA(
                        "Log_In ",
                        "User clicked on the agents login button inside pop up"
                      );
                    }}
                    className={checkDisabledButtonAgent(isAgentDisabled)}
                    disabled={isAgentDisabled}
                    type="submit"
                  >
                    {!isLoading ? (
                      <div>
                        <i
                          style={{ marginRight: "10px" }}
                          className="fas fa-sign-in-alt"
                        ></i>
                        <span>Login</span>
                      </div>
                    ) : (
                      <div>
                        <PuffLoader color={"white"} css={override} size={30} />
                      </div>
                    )}
                  </button>
                  <div style={{ marginTop: "1.5rem" }}>
                    <p
                      onClick={() => {
                        setIsForgotPasswordAgent(true);
                        GA(
                          "FORGOT_PASSWORD_AGENT_D",
                          "User clicked on the FORGOT_PASSWORD_AGENT on popup "
                        );
                      }}
                      className="forgot-password"
                    >
                      Reset Password
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div
              // className="Customers-Div"
              className={
                selectedLogintoDisplay === "AGENT"
                  ? "HIDE-ME Customers-Div"
                  : "Customers-Div"
              }
              id={
                selectedLogintoDisplay === "CUSTOMER"
                  ? !isMobile ? "" : "EXTEND-ME"
                  : "MINIMIZE-ME"
              }
              onClick={() => {
                if(isMobile){
                  dispatch({
                    type: "SET_LOGIN_PAGE_TO_DISPLAY",
                    payload: "CUSTOMER",
                  });
                }
                
              }}
            >
              <h2 className="blue-color">Customer Login</h2>
              <img
                alt="Customer login icon"
                src="/uaichome/pictures/Customer.png"
                className="customer-icon"
              ></img>
              <div
                className="field-container"
                id={
                  selectedLogintoDisplay === "CUSTOMER" ? "SHOW-ME" : "HIDE-ME"
                }
              >
                <form
                  action={selectCorrectActionForCustomers()}
                  name="mainFormCustomer"
                  id="mainFormCustomer"
                  method="POST"
                  //name="clogin"
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ display: "none" }}>
                      Select
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Policy"
                        className="radio"
                        onClick={() => {
                          setSelectedRadioButtonCustomer("insured_login");
                        }}
                        id="insured_login"
                        name="login_radio"
                        value="insured_login"
                        checked={
                          selectedRadioButtonCustomer === "insured_login" &&
                          true
                        }
                      />
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Claims"
                        className="radio"
                        onClick={() => {
                          setSelectedRadioButtonCustomer("claims_login");
                        }}
                        id="claims_login"
                        name="login_radio"
                        value="claims_login"
                        checked={
                          selectedRadioButtonCustomer === "claims_login" && true
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    //autoComplete="fname"
                    id={correctNamesAndIdsCustomer.id}
                    name={correctNamesAndIdsCustomer.nameUserId}
                    value={customerUsername}
                    placeholder="Username"
                    label="Username"
                    variant="outlined"
                    autoFocus
                    required
                    fullWidth
                    onChange={(e) => {
                      setCustomerUsername(e.target.value);
                    }}
                  />
                  <br></br>
                  <TextField
                    //autoComplete="fname"
                    id={correctNamesAndIdsCustomer.password}
                    name={correctNamesAndIdsCustomer.namePassword}
                    value={customerPassword}
                    type="password"
                    placeholder="Password"
                    label="Password"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(e) => {
                      setCustomerPassword(e.target.value);
                    }}
                  />

                  <button
                    className={checkDisabledButtonCustomer(isCustomerDisabled)}
                    disabled={isCustomerDisabled}
                    type="submit"
                    onClick={(e) => {
                      customerValidPost(e);
                      GA(
                        "Log_In ",
                        "User clicked on the customers login button inside pop up"
                      );
                    }}
                  >
                    {!isLoading ? (
                      <div>
                        <i
                          style={{ marginRight: "10px" }}
                          className="fas fa-sign-in-alt"
                        ></i>
                        <span>Login</span>
                      </div>
                    ) : (
                      <div>
                        <PuffLoader color={"white"} css={override} size={30} />
                      </div>
                    )}
                  </button>
                  <p className="payment-Shortcut">
                    <a
                      onClick={(e) => {
                        GA(
                          "Make_a_Payment",
                          "User clicked on the Make_a_Payment link inside login pop up"
                        );
                      }}
                      className="desktop"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#ffc107", textDecoration: "none" }}
                      href={`${selectCorrectUrls().insuredPortal}/insurednew/`}
                    >
                      Click to make a payment and Renew policy
                    </a>
                    <a
                      onClick={(e) => {
                        GA(
                          "Make_a_Payment",
                          "User clicked on the Make_a_Payment  Mobile link inside login pop up"
                        );
                      }}
                      className="mobile"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#ffc107", textDecoration: "none" }}
                      href={`${
                        selectCorrectUrls().insuredPortal
                      }/mobile/?rfm=yes`}
                    >
                      Click to make a payment and <br></br> Renew policy
                    </a>
                  </p>
                  <div
                    onClick={() => {
                      closeLoginMenu();
                    }}
                    className="new-user-div"
                    style={{ marginTop: "12px" }}
                  >
                    <Link
                      to="/uaichome/newuser/customer"
                      className="new-user"
                      style={{ marginTop: "1rem" }}
                      onClick={() => {
                        setIsMenuOpened(false);
                        GA(
                          "NEW_USER_M",
                          "User clicked on the NEW USER Customer in popup mobile"
                        );
                        dispatch({
                          type: "SET_SELECTED_PAGE_TO_DISPLAY",
                          payload: "NEW_USER",
                        });
                      }}
                    >
                      Create an Account
                    </Link>
                  </div>
                  <p
                    onClick={() => {
                      setIsForgotPasswordCustomer(true);
                      GA(
                        "FORGOT_PASSWORD_CUSTOMER_M",
                        "User clicked on the FORGOT_PASSWORD_CUSTOMER in popup mobile "
                      );
                    }}
                    className="forgot-password"
                  >
                    Reset Password
                  </p>
                  <a href="https://account-management-claims-uaic-gwcpprod-prod.api.delta4-andromeda.guidewire.net/account-management-claims/tpp-claims"><p
                    onClick={() => {
                      GA(
                        "CONTINUE_AS_GUEST",
                        "User clicked on the CONTINUE_AS_GUEST in popup mobile "
                      );
                    }}
                    className="forgot-password"
                  >
                    Continue As Guest
                  </p>
                  </a>
                </form>
              </div>
            </div>
            <div
              onClick={() => {
                closeLoginMenu();
                if (isMobileMenuOpen && isMenuOpened) {
                  setIsMenuOpened(!isMenuOpened);
                  dispatch({
                    type: "SET_LOGIN_PAGE_TO_DISPLAY",
                    payload: "NONE",
                  });
                  dispatch({
                    type: "OPEN_MOBILE_MENU",
                    // payload: "NONE",
                  });
                }
                dispatch({
                  type: "OPEN_MOBILE_MENU",
                  // payload: "NONE",
                });
              }}
              className="close-login-menu-icon"
            >
              <i
                className="fa fa-times-circle"
                style={{ fontSize: "2em", color: "#01224f" }}
                aria-hidden="true"
              ></i>
            </div>
            <div
              onClick={() => {
                // closeLoginMenu();
                dispatch({
                  type: "SET_LOGIN_PAGE_TO_DISPLAY",
                  payload: "BOTH",
                });
              }}
              className="close-login-menu-icon backButton"
              style={{ right: "25px !important" }}
              id={selectedLogintoDisplay !== "BOTH" ? "SHOW-ME" : "HIDE-ME"}
            >
              <i
                className="fa fa-arrow-left"
                style={{ fontSize: "1.5em", color: "#01224f" }}
                aria-hidden="true"
              >
                &nbsp;Back
              </i>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCorrectHeader = () => {
    if (windowWidth <= 1100) {
      return (
        <div tabIndex="-1">
          <div
            onClick={() => {
              closeLoginMenu();
            }}
            className={
              isLoginMenuOpen ||
              isAgentsLoginMenuOpen ||
              isCustomerLoginMenuOpen
                ? "darkened-background-on"
                : "darkened-background-off"
            }
          ></div>
          {isLoginMenuOpen && loginMenu()}
          <div
            style={
              location === "/uaichome" ||
              location === "/" ||
              location === "/uaichome/"
                ? windowScrollPosition <= 10 && isMenuOpened === false
                  ? { backgroundColor: "rgba(0,0,0,0)", transition: "0.3s" }
                  : { backgroundColor: "#09203f", transition: "0.3s" }
                : {}
            }
            className={
              isMenuOpened ? "header-mobile-open" : "header-mobile-closed"
            }
          >
            <div>
              <Link
                to="/uaichome"
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "HOME_PAGE_M",
                  });
                  GA(
                    "UAIC_LOGO",
                    "User clicked on the Logo button in the Mobile"
                  );
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Company logo"
                    className="logo-header"
                    src="/uaichome/pictures/United_transparent_white.png"
                   />
                </div>
              </Link>
              <div
                // style={{ position: "absolute", right: "50px", top: "18px" }}
                className="login-button"
              >
                <button
                  onClick={() => {
                    setIsForgotPasswordCustomer(false);
                    setIsForgotPasswordAgent(false);
                    dispatch({
                      type: "OPEN_LOGIN_MENU",
                    });
                    dispatch({
                      type: "SET_LOGIN_PAGE_TO_DISPLAY",
                      payload: "BOTH",
                    });

                    dispatch({
                      type: "SET_SELECTED_PAGE_TO_DISPLAY",
                      payload: "LOGIN_BUTTON_D",
                    });

                    //document.getElementById("agent_portal_radio").click();
                    //document.getElementById("customer_portal_radio").click();
                    GA(
                      "Login",
                      "User clicked on the login button in the header"
                    );
                  }}
                >
                  <i
                    style={{ marginRight: "10px" }}
                    className="fas fa-sign-in-alt"
                  ></i>
                  Login
                </button>
              </div>

              <span
                //  aria-label="menu"
                className="menu-bars"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "10px",
                  cursor: "pointer",
                  padding: "15px",
                  fontSize: "1.17em",
                }}
                onClick={() => {
                  setIsMenuOpened(!isMenuOpened);
                }}
              >
                {isMenuOpened ? (
                  <i style={{ fontSize: "30px" }} className="fas fa-times"></i>
                ) : (
                  <i className="fas fa-bars fa-2x"></i>
                )}
              </span>
            </div>
            <div className="navigation-links-div-mobile">
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA(
                    "Menu_Click",
                    "User clicked on the About Us in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "ABOUT_US_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkAboutUsClassNameMobile("/uaichome/aboutus")}
                to="/uaichome/aboutus"
              >
                <div>
                  <h3>About Us</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA("Menu_Click", "User clicked on the Claims in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "CLAIMS_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkClaimsClassNameMobile("/uaichome/claims")}
                to="/uaichome/claims"
              >
                <div>
                  <h3>Claims Portal</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA("Menu_Click", "User clicked on the BAA in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "BAA_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkBecomeAgentClassNameMobile(
                  "/uaichome/becomeagent"
                )}
                to="/uaichome/becomeagent"
              >
                <div>
                  <h3>Become an Agent</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA(
                    "Menu_Click",
                    "User clicked on the Insurance in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "INSURANCE_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkInsuranceClassNameMobile("/uaichome/insurance")}
                to="/uaichome/insurance"
              >
                <div>
                  <h3>Insurance</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA("Menu_Click", "User clicked on the Careers in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "CAREERS_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkCareersClassNameMobile("/uaichome/careers")}
                to="/uaichome/careers"
              >
                <div>
                  <h3>Careers</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA("Menu_Click", "User clicked on the Contact in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "CONTACT_US_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkContactClassNameMobile("/uaichome/contact")}
                to="/uaichome/contact"
              >
                <div>
                  <h3>Contact</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA(
                    "Menu_Click",
                    "User clicked on the Lienholder in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "LIENHOLDER_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkLeinHolderMobile("/uaichome/leinholder")}
                to="/uaichome/leinholder"
              >
                <div>
                  <h3>Leinholder</h3>
                </div>
              </Link>
              <Link
                onClick={() => {
                  setIsMenuOpened(false);
                  GA(
                    "Menu_Click",
                    "User clicked on the FindAnAgent in the header Mobile"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "FindAnAgent_M",
                  });
                }}
                style={{ padding: "10px", margin: "5px" }}
                className={checkFindAnAgentMobile("/uaichome/FindAnAgent")}
                to="/uaichome/FindAnAgent"
              >
                <div>
                  <h3>Find An Agent</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div>
          <div
            onClick={() => {
              closeLoginMenu();
            }}
            className={
              isLoginMenuOpen ||
              isAgentsLoginMenuOpen ||
              isCustomerLoginMenuOpen
                ? "darkened-background-on"
                : "darkened-background-off"
            }
          >
            <div className="get-a-quote-footer">
              <ul>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/site-map"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Site Map</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/site-requirements"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Site Requirements</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/accessibility"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Accessibility Statement</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/privacy-policy"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Privacy Policy</p>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to="/uaichome/contact"
                    onClick={() => {
                      scrollToTop(0);
                    }}
                  >
                    <p className="footer-links">Contact Us</p>
                  </Link>
                </li>
              </ul>
              <br></br>
            </div>
            <div className="get-a-quote-copy-rigth">
              <span>
                Copyright © {getCurrentYear()} United Automobile Insurance
                Company
              </span>
            </div>
          </div>
          {isLoginMenuOpen && loginMenu()}
          <div
            style={
              location === "/uaichome" ||
              location === "/" ||
              location === "/uaichome/"
                ? windowScrollPosition <= 10
                  ? {
                      backgroundColor: "rgba(0,0,0,0)",
                      transition: "0.3s",
                      color: "#09203f",
                    }
                  : { backgroundColor: "#09203f", transition: "0.3s" }
                : {}
            }
            className="header"
          >
            <div className="navigation-links">
              <Link
                onClick={() => {
                  GA("Menu_Click", "User clicked on the Logo in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "HOME_PAGE_D",
                  });
                }}
                style={{ marginRight: "1rem" }}
                to="/uaichome"
              >
                <div>
                  <img
                    alt="Company logo"
                    className="logo-header"
                 //   src="/uaichome/pictures/United_transparent_white.png"
                  src={windowScrollPosition <= 10 ? "/uaichome/pictures/United_transparent_white.png" :"/uaichome/pictures/United_transparent_white.png" }
               
                  />
                </div>
              </Link>
              <Link
                onClick={() => {
                  GA(
                    "Menu_Click",
                    "User clicked on the About Us in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "ABOUT_US_D",
                  });
                }}
                className={checkAboutUsClassName("/uaichome/aboutus")}
                to="/uaichome/aboutus"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "#09203f",
                            }
                            : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    About Us
                  </span>
                </div>
              </Link>

              <Link
                onClick={() => {
                  GA("Menu_Click", "User clicked on the Claims in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "CLAIMS_D",
                  });
                }}
                className={checkClaimsClassName("/uaichome/claims")}
                to="/uaichome/claims"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                            : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    Claims Portal
                  </span>
                </div>
              </Link>
              <Link
                className={checkBecomeAgentClassName("/uaichome/becomeagent")}
                to="/uaichome/becomeagent"
                onClick={() => {
                  GA(
                    "Menu_Click",
                    "User clicked on the Become an Agent in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "BAA_D",
                  });
                }}
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                            : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    Become an Agent
                  </span>
                </div>
              </Link>
              <Link
                onClick={() => {
                  GA(
                    "Menu_Click",
                    "User clicked on the Insurance in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "INSURANCE_D",
                  });
                }}
                className={checkInsuranceClassName("/uaichome/insurance")}
                to="/uaichome/insurance"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                            : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    Insurance
                  </span>
                </div>
              </Link>
              <Link
                onClick={() => {
                  GA("Menu_Click", "User clicked on the Careers in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "CAREERS_D",
                  });
                }}
                className={checkCareersClassName("/uaichome/careers")}
                to="/uaichome/careers"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                            : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    Careers
                  </span>
                </div>
              </Link>
              <Link
                onClick={() => {
                  GA("Menu_Click", "User clicked on the Contact in the header");

                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "CONTACT_US_D",
                  });
                }}
                className={checkContactClassName("/uaichome/contact")}
                to="/uaichome/contact"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                            : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    Contact
                  </span>
                </div>
              </Link>
              <Link
                onClick={() => {
                  GA(
                    "Menu_Click",
                    "User clicked on the Lienholder in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "LIENHOLDER_D",
                  });
                }}
                className={checkLeinHolder("/uaichome/leinholder")}
                to="/uaichome/leinholder"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                          : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    LienHolder
                  </span>
                </div>
              </Link>
              <Link
                onClick={() => {
                  GA(
                    "Menu_Click",
                    "User clicked on the FindAnAgent in the header"
                  );
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "FindAnAgent_D",
                  });
                }}
                className={checkLeinHolder("/uaichome/FindAnAgent")}
                to="/uaichome/FindAnAgent"
                style={{display:""}}
                id="find-an-agent"
              >
                <div>
                  <span
                    style={
                      location === "/uaichome" ||
                      location === "/" ||
                      location === "/uaichome/"
                        ? windowScrollPosition <= 10
                          ? {
                              transition: "0.3s",
                              color: "white",
                              fontWeight: "bold",
                              borderBottomColor: "white",
                            }
                          : { transition: "0.3s",  fontWeight: "bold", }
                        : {}
                    }
                  >
                    Find An Agent
                  </span>
                </div>
              </Link>
            </div>
            <div className="login-button">
              <button
                onClick={() => {
                  dispatch({
                    type: "OPEN_LOGIN_MENU",
                  });
                  dispatch({
                    type: "SET_LOGIN_PAGE_TO_DISPLAY",
                    payload: "BOTH",
                  });
                  dispatch({
                    type: "SET_SELECTED_PAGE_TO_DISPLAY",
                    payload: "LOGIN_BUTTON_M",
                  });
                  GA("Login", "User clicked on the login button in the header");
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      );
  };

  return <div>{renderCorrectHeader()}</div>;
};

export default Header;
